export default {
  title: 'Confira como declarar sua previdência',
  subtitle:
    'Aqui, você encontra o passo a passo de como conseguir o seu informe de rendimento para fazer a sua declaração.',
  steps: [
    {
      step: 'Passo 01',
      title: 'Tenha seu informe de rendimentos',
      subtitle: 'Você consegue o seu informe pelo portal oficial da nossa parceira **Icatu**.',
      type: 'stepByStep',
      options: [
        {
          id: 1,
          text: 'Mostrar passo a passo',
        },
      ],
    },
    {
      step: 'Passo 02',
      title: 'Identifique qual o seu plano',
      subtitle:
        'Confira no seu informe qual é o tipo de previdência do seu contrato ativo ou encerrado no ano base da declaração.',
      options: [],
    },
    {
      step: 'Passo 03',
      title: 'Faça sua declaração',
      subtitle: 'Saiba como declarar seus proventos e aplicações de acordo com o seu produto.',
      type: 'pension',
      options: [
        {
          id: 2,
          text: 'Previdência PGBL',
        },
        {
          id: 3,
          text: 'Previdência VGBL',
        },
      ],
    },
  ],
  stepByStep: [
    {
      id: 1,
      textAfterClick: 'Ocultar passo a passo',
      steps: [
        {
          icon: 'number_one',
          title: 'Acesse o site da Icatu Seguros',
          description: '[Acessar o portal Icatu](https://www.bancointer.com.br/)',
        },
        {
          icon: 'number_two',
          title: 'Acesse a Área do Cliente',
          description: '**Faça o seu login**, digiando o seu CPF e sua senha de acesso.',
        },
        {
          icon: 'number_three',
          title: 'Faça o download do seu Informe',
        },
      ],
      important: {
        icon: 'info',
        title: 'Não encontrou seu informe?',
        subtitle:
          'Caso você não tenha recebido renda ou resgate ou realizado contribuição ou aporte no ano anterior, não é necessário fazer a declaração. Por isso, seu Informe de Rendimentos não foi gerado.',
      },
    },
  ],
  pension: [
    {
      id: 2,
      description: 'Você fez alguma movimentação no ano passado?',
      buttons: [
        {
          id: 1,
          text: 'Fiz contribuições ou aportes',
        },
        {
          id: 2,
          text: 'Fiz resgate ou recebi renda',
          description: 'Tabela progressiva',
        },
        {
          id: 3,
          text: 'Fiz resgate ou recebi renda',
          description: 'Tabela regressiva',
        },
      ],
      options: [
        {
          id: 1,
          title: 'Previdência PGBL com contribuições ou aportes',
          subtitle: 'Aqui, você encontra o passo a passo de como fazer a sua declaração.',
          steps: [
            {
              currentStep: 'Passo 01',
              title: 'Dedução do importo de renda',
              content:
                'Você pode abater a soma das suas contribuições da base de cálculo do Imposto de Renda.',
              buttonVisible: true,
              about: {
                title: 'Saiba mais',
                description:
                  'Como participante de um plano de Previdência Complementar, você pode abater até 12% da sua renda bruta tributável da soma das suas contribuições da base de cálculo do Imposto de Renda. A dedução das contribuições só é possível por meio do modelo completo de declaração de renda.<br/><br/> Se você realizou contribuições e\\/ou aportes em 2020, a tabela presente no **Item 8 - Informações Complementares**, do seu Informe de Rendimentos, estará preenchida.',
              },
              important: {
                icon: 'info',
                title: 'Quem pode fazer a dedução do IR',
                description:
                  'A dedução só pode ser feita por quem utiliza o **formulário completo de Declaração de Ajuste Anual do Imposto de Renda** e contribui para o **Regime Geral de Previdência Social**, conforme a legislação em vigor.',
              },
            },
            {
              currentStep: 'Passo 02',
              title: 'Pagamentos Efetuados',
              subtitle: '**Cód. 36** - Previdência Complementar',
              content:
                'O valor total de contribuição e aporte deve ser informado na ficha de **Pagamentos Efetuados**. Você encontra essas informações no **Item 8** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
            {
              currentStep: 'Passo 03',
              title: 'Bens e Direitos',
              subtitle: '**Cód. 99** - Outros Bens e Direitos',
              content:
                'Informe o saldo do último dia do ano anterior e o saldo final do último dia do ano vigente da declaração.',
              buttonVisible: false,
            },
          ],
        },
        {
          id: 2,
          title: 'Previdência PGBL - Tabela progressiva',
          subtitle:
            'Se ao longo do ano base, você recebeu renda ou efetuou resgates do seu plano, é necessário declarar esses valores.',
          steps: [
            {
              currentStep: 'Passo 01',
              title: 'Rendimentos Tributáveis Recebidos de PJ',
              content:
                'Informe o CNPJ da fonte pagadora, os rendimentos recebidos de PJ e os impostos retidos na fonte. Você encontra essas informações no **Item 3** do seu Informe de Rendimentos.',
              buttonVisible: false,
              important: {
                icon: 'info',
                title: 'Importante',
                description:
                  'No momento de resgate, a incidência de IR na fonte ocorre de maneira antecipada à alíquota única de 15%. No recebimento de renda, há incidência de imposto, conforme a Tabela Progressiva de IR.<br/><br/> Os valores pagos de IR serão compensados ou restituídos na sua Declaração de Ajuste Anual de IR.',
              },
            },
            {
              currentStep: 'Passo 02',
              title: 'Rendimentos Isentos e não Tributáveis',
              subtitle: '**Cód. 26** - Outros',
              content:
                'Os valores isentos pagos a título de Resgates e Benefícios estão disponíveis na coluna “Rendimentos” do **Item 4** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
          ],
        },
        {
          id: 3,
          title: 'Previdência PGBL - Tabela regressiva',
          subtitle:
            'Se ao longo do ano base, você recebeu renda ou efetuou resgates do seu plano, é necessário declarar esses valores.',
          steps: [
            {
              currentStep: 'Passo 01',
              title: 'Rendimentos Sujeitos à Tributação Exclusiva\\/Definitiva',
              subtitle: '**Cód. 12** - Outros',
              content:
                'Nessa ficha de preenchimento, informe os **valores de Rendimentos Recebidos**. Você encontra essas informações no **Item 5** do seu Informe de Rendimentos.',
              buttonVisible: false,
              important: {
                icon: 'info',
                title: 'Sobre a Tabela Regressiva',
                description:
                  'Ela apresenta seis faixas de alíquotas decrescentes, conforme o prazo de acumulação das suas contribuições e aportes. É um incentivo à poupança de longo prazo, já que o imposto diminui com tempo.',
              },
            },
            {
              currentStep: 'Passo 02',
              title: 'Rendimentos Isentos e não Tributáveis',
              subtitle: '**Cód. 26** - Outros',
              content:
                'Os valores isentos pagos a título de Resgates e Benefícios estão disponíveis na coluna “Rendimentos” do **Item 4** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
          ],
        },
      ],
    },
    {
      id: 3,
      description: 'Você fez alguma movimentação ano passado?',
      buttons: [
        {
          id: 1,
          text: 'Fiz contribuições ou aportes',
        },
        {
          id: 2,
          text: 'Fiz resgate ou recebi renda',
          description: 'Tabela progressiva',
        },
        {
          id: 3,
          text: 'Fiz resgate ou recebi renda',
          description: 'Tabela regressiva',
        },
      ],
      options: [
        {
          id: 1,
          title: 'Previdência VGBL com contribuições ou aportes',
          subtitle:
            'Nos planos VGBL, as contribuições e aportes não são dedutíveis da base de cálculo do Imposto de Renda. Confira como declarar:',
          steps: [
            {
              title: 'Bens e direitos',
              subtitle: '**Cód. 97** - VGBL – Vida Gerador de Benefício Livres',
              content:
                'Você precisa demostrar o saldo restante de todos os investimentos mensais ou aportes efetuados no VGBL, para demonstrar a evolução patrimonial.<br/><br/> Para isso, informe o saldo do último dia do ano anterior e o saldo final do último dia do ano vigente da declaração.<br/><br/> Você encontra essas informações no **Item 6** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
          ],
        },
        {
          id: 2,
          title: 'Previdência VGBL - Tabela progressiva',
          subtitle:
            'Se ao longo do ano base, você recebeu renda ou efetuou resgates do seu plano, é necessário declarar esses valores. Nos planos de VGBL, é tributada apenas a rentabilidade obtida dos valores aplicados na reserva.',
          steps: [
            {
              currentStep: 'Passo 01',
              title: 'Rendimentos Tributáveis Recebidos de PJ',
              content:
                'Informe o CNPJ da fonte pagadora, os rendimentos recebidos de PJ e os impostos retidos na fonte. Você encontra essas informações no **Item 3** do seu Informe de Rendimentos.',
              buttonVisible: false,
              important: {
                icon: 'info',
                title: 'Importante',
                description:
                  'No momento de resgate, a incidência de IR na fonte ocorre de maneira antecipada à alíquota única de 15%. No recebimento de renda, há incidência de imposto, conforme a Tabela Progressiva de IR.<br/><br/> Os valores pagos de IR serão compensados ou restituídos na sua Declaração de Ajuste Anual de IR.',
              },
            },
            {
              currentStep: 'Passo 02',
              title: 'Rendimentos Isentos e não Tributáveis',
              subtitle: '**Cód. 26** - Outros',
              content:
                'Os valores isentos pagos a título de Resgates e Benefícios estão disponíveis na coluna “Rendimentos” do **Item 4** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
          ],
        },
        {
          id: 3,
          title: 'Previdência VGBL - Tabela regressiva',
          subtitle:
            'Se ao longo do ano base, você recebeu renda ou efetuou resgates do seu plano, é necessário declarar esses valores. Nos planos de VGBL, é tributada apenas a rentabilidade obtida dos valores aplicados na reserva.',
          steps: [
            {
              currentStep: 'Passo 01',
              title: 'Rendimentos Sujeitos à Tributação Exclusiva\\/Definitiva',
              subtitle: '**Cód. 12** - Outros',
              content:
                'Nessa ficha de preenchimento, informe os valores de Rendimentos Recebidos. Você encontra essas informações no **Item 5** do seu Informe de Rendimentos.',
              buttonVisible: false,
              important: {
                icon: 'info',
                title: 'Sobre a Tabela Regressiva',
                description:
                  'Ela apresenta seis faixas de alíquotas decrescentes, conforme o prazo de acumulação das suas contribuições e aportes. É um incentivo à poupança de longo prazo, já que o imposto diminui com tempo.',
              },
            },
            {
              currentStep: 'Passo 02',
              title: 'Rendimentos Isentos e não Tributáveis',
              subtitle: '**Cód. 26** - Outros',
              content:
                'Os valores isentos pagos a título de Resgates e Benefícios estão disponíveis na coluna “Rendimentos” do **Item 4** do seu Informe de Rendimentos.',
              buttonVisible: false,
            },
          ],
        },
      ],
    },
  ],
}
