import React, { useState } from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { Accordion } from '@/components'

import { PensionCardImportant } from '../pension-card-important'
import * as S from './styles'

interface StepPensionProps {
  cardStep: PensionPensionOptionsSteps
}

export const StepPensionOptions = ({ cardStep }: StepPensionProps) => {
  const [expanded, setExpanded] = useState('')
  const { title, subtitle, currentStep, content, important, buttonVisible, about } = cardStep

  return (
    <S.Container>
      <S.Text variant="body-3" bold>
        <ReactMarkdown>{currentStep || ''}</ReactMarkdown>
      </S.Text>

      <S.Space />

      <S.Text variant="headline-h3" semiBold>
        <ReactMarkdown>{title || ''}</ReactMarkdown>
      </S.Text>

      <S.Space />

      <S.Text variant="body-3">
        <ReactMarkdown>{subtitle || ''}</ReactMarkdown>
      </S.Text>
      <S.Space />

      <S.Text variant="body-3">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content || ''}</ReactMarkdown>
      </S.Text>

      {buttonVisible && (
        <Accordion title="Saiba mais" id={`${title}`} expanded={expanded} setExpanded={setExpanded}>
          <S.ContentAbout>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{about?.description || ''}</ReactMarkdown>
            <PensionCardImportant info={important} />
          </S.ContentAbout>
        </Accordion>
      )}

      {important && !buttonVisible && <PensionCardImportant info={important} />}
    </S.Container>
  )
}
