import React from 'react'

import * as Skeletons from '@/components/skeletons/styles'
import { Accordion } from '@/components/skeletons'

export const LoadingSkeleton = () => (
  <div>
    <Skeletons.Content height="80" />
    <Skeletons.Content height="50" />

    <Skeletons.Line />

    <Accordion />
    <Accordion />
    <Accordion />

    <Skeletons.Line />

    <Skeletons.Content height="80" />
    <Skeletons.Content height="50" />

    <Skeletons.Line />

    <Accordion />
    <Accordion />
  </div>
)
