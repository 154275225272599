import React from 'react'

import { useHistory } from 'react-router-dom'

import { MenuItem } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

interface IHistoryState {
  choiceYear: string
}

export const PartnerProducts = ({
  title,
  description,
  options,
}: DeclarationFacilitatedMetadataPartnerProducts) => {
  const history = useHistory<IHistoryState>()

  const handleNavigate = (url: string) => {
    history.push(url)
  }

  return (
    <S.Container>
      <S.Text variant="headline-h3" semiBold colorWeight={500}>
        {title}
      </S.Text>

      <S.Space />

      <S.Text variant="body-3">{description}</S.Text>

      <S.Space />

      <S.PartnerSectionContainer>
        {options.includes('CONSORTIUM') && (
          <MenuItem
            title="Consórcio"
            onClick={() => {
              handleNavigate(RoutesNames.CONSORTIUM)
              requestAnalytics('AcessoProdParcConsorcio')
            }}
          />
        )}
        {options.includes('PENSION') && (
          <MenuItem
            title="Previdência"
            onClick={() => {
              handleNavigate(RoutesNames.PENSION)
              requestAnalytics('AcessoProdParcPrevidencia')
            }}
          />
        )}
      </S.PartnerSectionContainer>
    </S.Container>
  )
}
