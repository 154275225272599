import React from 'react'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import { ProductComponet } from '../product'
import * as S from './styles'

export const ScrollingCarousel = ({
  productDetails,
  openBottomSheet,
}: ScrollingCarousel & {
  openBottomSheet: (data: BottomSheet) => void
}) => (
  <S.Slider>
    <S.Slides>
      {productDetails.map((product, index) => (
        <Flex
          justifyContent="flex-end"
          direction="column"
          alignContent="flex-end"
          alignItems="flex-end"
          gap="8px"
        >
          <S.CurrentPageSlider>
            <Text variant="caption-1" bold>
              {index + 1}/{productDetails.length}
            </Text>
          </S.CurrentPageSlider>
          <ProductComponet
            {...product}
            openBottomSheet={(data) => {
              openBottomSheet(data)
            }}
          />
        </Flex>
      ))}
    </S.Slides>
  </S.Slider>
)
