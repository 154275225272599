import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'
import { Icon as IconUI } from '@/components/IconComponent'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Separator = styled.div`
  background-color: var(--gray100);
  height: 8px;
  margin: 0 -24px;
`

export const Text = styled(TextUI)``

export const ContainerLoading = styled(GlobalStyles.DefaultContainer)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const Icon = styled(IconUI)``
