import React, { useEffect } from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { usePension } from '@/hooks'

import * as S from './styles'
import { StepPension } from '../step-pension'
import { StepPensionOptions } from '../step-pension-options'

interface PensionPageProps {
  dataPension: Pension
  isDetailsPage: boolean
  onNavPage: (value: boolean) => void
}

export const PensionPage = ({ dataPension, isDetailsPage, onNavPage }: PensionPageProps) => {
  const { pageOption, handleSelectPage } = usePension()

  useEffect(() => {
    if (pageOption) {
      onNavPage(true)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [pageOption])

  useEffect(() => {
    if (!isDetailsPage) {
      handleSelectPage(undefined)
    }
  }, [isDetailsPage])

  if (pageOption) {
    return (
      <div>
        <S.Space height={14} />
        <S.Text variant="headline-h1" semiBold>
          {pageOption?.title}
        </S.Text>
        <S.Space height={14} />
        <S.Text variant="body-3">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageOption?.subtitle}</ReactMarkdown>
        </S.Text>
        <S.Space height={24} />

        {pageOption?.steps.map((item) => (
          <div key={`${item.title}`}>
            <S.Separator />
            <S.Space height={24} />
            <StepPensionOptions cardStep={item} />
            <S.Space height={24} />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <S.Space height={14} />
      <S.Text variant="headline-h1" semiBold>
        {dataPension?.title}
      </S.Text>
      <S.Space height={14} />
      <S.Text variant="body-3">{dataPension?.subtitle}</S.Text>
      <S.Space height={24} />

      {dataPension?.steps.map((item) => (
        <div key={`${item.title}`}>
          <S.Separator />
          <S.Space height={24} />
          <StepPension
            cardStep={item}
            stepBySteps={dataPension.stepByStep}
            pension={dataPension.pension}
          />
          <S.Space height={24} />
        </div>
      ))}
    </div>
  )
}
