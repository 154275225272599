import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { replaceTag } from '@/utils/replaceTags/replaceTags'

import * as S from './styles'

export const ItemWithBackground = ({ text, backgroundColor }: ItenWithBackgroundColor) => (
  <S.Container color={backgroundColor} data-testid="itemWithBackground">
    <Text variant="caption-1">
      {replaceTag({
        info: text,
        tagList: [
          {
            renderComponent: (value: string) => (
              <Text variant="body-3" bold colorWeight={500}>
                {value}
              </Text>
            ),
            tag: 'b',
          },
        ],
      })}
    </Text>
  </S.Container>
)
