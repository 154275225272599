import styled from 'styled-components'

import { Skeleton as UISkeleton, SkeletonProps } from '@interco/inter-ui/components/Skeleton'

interface IProps extends SkeletonProps {
  height: string
  width?: string
  marginBottom?: number
  display?: string
}

export const Skeleton = styled(UISkeleton).attrs({
  animation: 'wave',
})``

export const Content = styled(Skeleton)<IProps>`
  height: ${(props) => `${props.height}px`};
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : '0px')};
`

export const Circle = styled(Content)`
  border-radius: var(--radiiXlg);
`

export const ContentBlogThumb = styled(Skeleton)`
  height: 60px;
  width: 70px;
  margin-right: 10px;
`

export const AccordionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  column-gap: 10px;
  margin-bottom: 10px;
`

export const Line = styled(Skeleton)`
  margin: 20px 0px;
  height: 5px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 24px;
`

export const ContainerBlog = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContentBlogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`
export const ContentBlog = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`

export const ContentBlogInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
