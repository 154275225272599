export enum ServiceName {
  FIXED_INCOME = 'Renda fixa',
  TREASURY_DIRECT = 'Tesouro direto',
  INVESTMENT_FUND = 'Fundos de investimento',
  REAL_ESTATE_FUND = 'Fundos Imobiliários (administrados pelo Inter)',
  CHECKING_ACCOUNT = 'Conta corrente',
  SAVINGS_ACCOUNT = 'Conta poupança',
  VARIABLE_INCOME = 'Renda variável',
  REAL_ESTATE_LOAN = 'Empréstimo imobiliário',
  PAYROLL_LOAN = 'Empréstimo consignado',
  PERSONAL_LOAN = 'Empréstimo pessoal',
  RURAL_FINANCE = 'Empréstimo rural',
  SAVINGS_FUND = 'Poupança',
  CONSORTIUM = 'Consórcio',
}

export enum ActionIdEnum {
  COPY_PAST = 'COPY_PAST',
  BOTTOM_SHEET = 'BOTTOM_SHEET',
}
