import styled, { css } from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'

export const Container = styled.div<{ color: string }>`
  background: var(${({ color }) => color});
  border-radius: 4px;
  padding: 8px 4px;
`

export const LabelText = styled(Text)``
export const LabelTextAlignEnd = styled(Text)`
  text-align: end;
`
export const ButtonIcon = styled(Button).attrs({
  variant: 'link',
})`
  padding: 0px;
  padding-left: 4px;
  padding-right: 4px;
`
export const SectionMaxWidth = styled.div`
  display: flex;
  flex: 1;
`

export const Tooltip = styled.div<{ active: boolean }>`
  ${({ active }) => css`
    position: relative;
    visibility: hidden;
    opacity: 0;
    span {
      width: 85px;
      background-color: var(--gray500);
      color: var(--neutral-theme);
      text-align: center;
      border-radius: var(--radiiMd);
      padding: 5px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -98px;
      -webkit-transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }

    ${active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  `}
`
