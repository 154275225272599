import styled from 'styled-components'

import { BottomSheet as BottomSheetUI } from '@interco/inter-ui/components/BottomSheet'

export const BottomSheet = styled(BottomSheetUI)``

export const Slider = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
`

export const CurrentPageSlider = styled.div`
  text-align: center;
  overflow: hidden;
  border: var(--gray300) solid 1px;
  border-radius: 16px;
  padding: 2px 8px;
`
export const Slides = styled.div`
  display: flex;
  padding-bottom: 16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  & > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    width: 100%;
    margin-right: 50px;
    border-radius: 10px;
    background: transparent;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
  }
`
