import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Icon } from '@/components/IconComponent'

import * as S from './styles'

export const ButtonToNewPageDetail = ({
  title,
  description,
  button,
  handleNavigation,
}: SectionWithButtonAndNewPageDetail & {
  handleNavigation: () => void
}) => (
  <S.ColumnFlex gap="12px">
    <Text variant="headline-h3" semiBold>
      {title}
    </Text>
    <Text variant="body-3">{description}</Text>

    <S.Button
      onClick={handleNavigation}
      icon={button.icon && <Icon name={button.icon.id} color="var(--neutral-theme)" />}
      fullWidth
      data-testid="button_open_detail_page"
    >
      {button.title}
    </S.Button>
  </S.ColumnFlex>
)
