import React from 'react'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { RoutesNames } from '@/routes/routes'
import { Step, Header, LoadingSkeleton } from '@/components'
import * as ApiService from '@/services/api'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

export const Consortium = () => {
  const history = useHistory()
  const [{ user }] = useGlobalState()

  const { isLoading, data } = useQuery<Consortium | undefined>(
    'Consortium',
    async () =>
      ApiService.Consortium.getConsortium({
        account: user?.account as string,
        accountType: 'PF',
      }),
    { retry: false, cacheTime: 0, refetchOnWindowFocus: false },
  )

  return (
    <S.Container>
      {isLoading && <LoadingSkeleton />}

      {!isLoading && data && (
        <>
          <Header
            onRightButtonClick={() => history.push(RoutesNames.HOME)}
            rightIcon="home"
            onBackClick={() => history.goBack()}
          >
            Consórcio
          </Header>

          <S.Space height={14} />

          <S.Text variant="headline-h1" semiBold>
            {data.title}
          </S.Text>

          <S.Space height={14} />

          <S.Text variant="body-3">{data.subtitle}</S.Text>

          <S.Space height={24} />

          <S.Sepator />
          {data.steps.map((item, index) => (
            <React.Fragment key={item.title}>
              <Step
                title={item.title}
                subtitle={item.subtitle}
                step={index + 1}
                steps={item.options}
                stepByStep={data.stepByStep}
              />
              <S.Space height={24} />
              <S.Sepator />
            </React.Fragment>
          ))}
        </>
      )}
    </S.Container>
  )
}
