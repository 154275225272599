import styled, { css } from 'styled-components'

import { Button as ButtonUI } from '@interco/inter-ui/components/Button'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'
import { Flex } from '@interco/inter-ui/components/Flex'

export const Container = styled.div``

export const ContainerBox = styled.div``

export const Row = styled.ul``

interface IRowProps {
  oneItem?: boolean
}
export const RowItem = styled.li<IRowProps>`
  ${({ oneItem }) => css`
    height: 32px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${oneItem &&
    css`
      justify-content: flex-start;
      display: none;
    `}
  `}
`
export const RowItemSeparator = styled.div`
  border-bottom: 1px dashed var(--gray200);
  margin: 20px auto;
  width: 80%;
`

export const Text = styled(TextUI)`
  strong {
    color: var(--primary500);
  }
`

export const Group = styled(TextUI)`
  background: var(--gray100);
  border-radius: var(--radiiSm);
  padding: 8px;
  display: block;
  margin-bottom: 8px;
`

export const BoxGroup = styled.div<{ isGroup: boolean }>`
  ${({ isGroup }) => css`
    ${isGroup &&
    css`
      padding-left: 8px;
      padding-right: 8px;
    `}
  `}
`

export const TextRight = styled(TextUI)`
  text-align: right;
  padding-left: 6px;
`

export const TextLine = styled(TextUI)`
  display: flex;
  align-items: center;
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Button = styled(ButtonUI)`
  width: 100%;
`

export const ContainerIconClick = styled.div``

export const ButtonCopy = styled.button`
  border: none;
  background: none;
  height: 18px;
`

export const ButtonHelp = styled.button`
  border: none;
  background: none;
  height: 18px;
`

export const Tooltip = styled.div<{ active: boolean }>`
  ${({ active }) => css`
    position: relative;
    visibility: hidden;
    opacity: 0;
    span {
      width: 85px;
      background-color: var(--gray500);
      color: var(--neutral-theme);
      text-align: center;
      border-radius: var(--radiiMd);
      padding: 5px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -98px;
      -webkit-transition: opacity 0.3s;
      -webkit-transition: opacity 0.3s;
      transition: opacity 0.3s;
    }

    ${active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  `}
`
export const LabelTextAlignEnd = styled(Text)`
  text-align: end;
`
export const SectionMaxWidth = styled(Flex).attrs({
  direction: 'row',
  alignItems: 'flex-start',
})`
  display: flex;
  flex: 1;
  height: 100%;
`
