export default {
  title: 'Confira como declarar seu consórcio',
  subtitle:
    'Aqui, você encontra o passo a passo de como conseguir o seu informe de rendimento para fazer a sua declaração',
  steps: [
    {
      step: 'Passo 01',
      title: 'Tenha seu informe de rendimentos',
      subtitle:
        'Você consegue o seu informe pelos canais do nosso parceiro **Bamaq**. Confira como:',
      options: [
        { id: 1, text: 'Pelo App' },
        { id: 2, text: 'Pelo Portal Web' },
        { id: 3, text: 'Pelo WhatsApp' },
      ],
    },
    {
      step: 'Passo 02',
      title: 'Preencha a ficha de bens e direitos',
      subtitle:
        'Com o seu informe de rendimentos em mãos, basta preencher a ficha de bens e direitos.',
      options: [{ id: 4, text: 'Mostrar passo a passo' }],
    },
    {
      step: 'Passo 03',
      title: 'Em caso de consórcio contemplado',
      subtitle: 'Você precisa “dar baixa” na sua ficha de consórcio e atualizá-la.',
      options: [{ id: 5, text: 'Mostrar passo a passo' }],
    },
  ],
  stepByStep: [
    {
      id: 1,
      content: [
        {
          icon: 'number_one',
          text: 'Baixe o app **Bamaq Consórcio APP do Cliente** e faça o seu login.',
        },
        { icon: 'number_two', text: 'Na tela inicial, clique em **Extrato completo**.' },
        {
          icon: 'number_three',
          text: 'Em seguida, clique na opção **Demonstrativo de imposto de renda**.',
        },
        {
          icon: 'number_four',
          text: 'O demonstrativo será exibido e você poderá **efetuar o download** do arquivo em PDF.',
        },
      ],
    },
    {
      id: 2,
      content: [
        {
          icon: 'number_one',
          text: 'Acesse o site da Bamaq Consórcios e clique na **área do cliente**. [Acessar o portal Bamaq](https://www.bancointer.com.br/)',
        },
        {
          icon: 'number_two',
          text: '**Faça o seu login** digitando o seu CPF e sua senha de acesso',
        },
        { icon: 'number_three', text: 'Na tela inicial, clique em **Extrato completo**' },
        {
          icon: 'number_four',
          text: 'Em seguida, clique na opção **Demonstrativo de imposto de renda**.',
        },
        {
          icon: 'number_five',
          text: 'O demonstrativo será exibido e você poderá **efetuar o download** do arquivo em PDF.',
        },
      ],
    },
    {
      id: 3,
      content: [
        { icon: 'number_one', text: 'Salve o contato **3369-1699** na sua agenda de telefone.' },
        {
          icon: 'number_two',
          text: 'Inicie uma conversa pelo whatsapp e escolha a **opção 3** (autoatendimento).',
        },
        {
          icon: 'number_three',
          text: 'Escolha a opção para solicitar documentos, na **opção 2**.',
        },
        {
          icon: 'number_four',
          text: 'Selecione a opção para receber seu informe de rendimentos, na **opção 2**.',
        },
        {
          icon: 'number_five',
          text: 'Você receberá uma mensagem de **confirmação de autenticidade**. Siga os passos solicitados.',
        },
        {
          icon: 'number_six',
          text: 'Após a confirmação, você receberá o link para acessar o seu **informe de rendimentos**.',
        },
      ],
    },
    {
      id: 4,
      textAfterClick: 'Ocultar passo a passo',
      content: [
        {
          icon: 'number_one',
          text: 'Digite o código da operação',
          description: '**Código 95** - Consórcio não contemplado',
        },
        {
          icon: 'number_two',
          text: 'Preencha os dados da corretora',
          description:
            'Você encontra essas informações no seu informe de rendimentos que você obteve no passo anterior.',
        },
        {
          icon: 'number_three',
          text: 'No campo discriminação',
          description:
            'Informe os detalhes do consórcio, como o nome da administradora, o bem que pretende adquirir, valor da carta e das parcelas pagas no ano base.',
        },
      ],
    },
    {
      id: 5,
      textAfterClick: 'Ocultar passo a passo',
      content: [
        {
          icon: 'number_one',
          text: 'Envie a declaração do ano anterior',
          description:
            'Ao importar a declaração do ano anterior, sua ficha aparecerá automaticamente na sua relação de bens.',
        },
        {
          icon: 'number_two',
          text: 'Atualize sua ficha de consórcio',
          description:
            'No área de **Discriminação**, preencha as parcelas já pagas até a data de contemplação. Também indique se foi por sorteio ou por lance. Em caso de lance, informe qual foi o valor.',
        },
        {
          icon: 'number_three',
          text: 'Abra outra ficha de Bens e Direitos',
          description:
            'Declare o bem adquirido e atualize o valor pago a partir da data de contemplação a cada ano.',
        },
      ],
    },
  ],
}
