import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { ApiService } from '@/services'
import { RoutesNames } from '@/routes/routes'
import { Step, Header, BottonSheetSimple, VideoPlayer } from '@/components'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'
import { DiscoverOurTools } from './components'

export const VariableIncome = () => {
  const history = useHistory()
  const [{ user }] = useGlobalState()
  const [showBottonSheet, setShowBottonSheet] = useState<boolean>(false)

  const { isLoading, data } = useQuery<VariableIncome | undefined>(
    'variableIncome',
    async () =>
      ApiService.VariableIncome.getVariableIncome({
        account: user?.account as string,
        accountType: 'PF',
      }),
    { refetchOnWindowFocus: false },
  )

  if (isLoading) {
    return (
      <S.ContainerLoading>
        <S.Icon name="loading" width={24} height={24} />
      </S.ContainerLoading>
    )
  }

  return (
    <S.Container>
      <Header
        onRightButtonClick={() => history.push(RoutesNames.HOME)}
        rightIcon="home"
        onBackClick={() => history.goBack()}
      >
        Renda variável
      </Header>

      <S.ContainerVideo>
        <VideoPlayer src="https://www.youtube.com/embed/raieHC4RKWg" />
      </S.ContainerVideo>

      <S.Space height={24} />
      <S.Text variant="headline-h1" semiBold>
        {data?.title}
      </S.Text>

      <S.Space height={12} />
      <S.Text variant="body-3">{data?.subtitle}</S.Text>

      <S.Space height={24} />
      <S.Sepator />

      {data?.steps.map((item, index) => (
        <React.Fragment key={item.title}>
          <Step
            title={item.title}
            important={data.important}
            subtitle={item.subtitle}
            step={index + 1}
            steps={item.options}
            stepByStep={data.stepByStep}
            onShowBottonSheet={() => setShowBottonSheet(true)}
          />
          <S.Space height={24} />
          <S.Sepator />
        </React.Fragment>
      ))}

      <DiscoverOurTools
        title={data?.footer.title || ''}
        description={data?.footer.description || ''}
        content={data?.footer.content || []}
      />

      <BottonSheetSimple
        title={data?.bottomsheet?.title || ''}
        description={data?.bottomsheet?.description || ''}
        isVisible={showBottonSheet}
        onClose={() => setShowBottonSheet(false)}
      />
    </S.Container>
  )
}
