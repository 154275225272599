import React, { useMemo, useState } from 'react'

import useGlobalState from '@/contexts/global-state'
import { BottonSheetDemonstrative, BottonSheetDemonstrativeSendedEmail } from '@/components'

import * as S from './styles'

import { Product, ProductRetrotive, ProductNotAvailable } from '..'

import { ProductWithoutIr } from '../product-without-ir'

export const Demonstrative = ({
  choiceYear,
  demonstrative,
  yearWithoutIr,
  data,
}: Partial<Home>) => {
  const [{ user }] = useGlobalState()
  const [fileDownload, setFileDownload] = useState<HomePfd>()
  const [isVisibleSheetDemonstrative, setIsVisibleSheetDemonstrative] = useState<boolean>(false)
  const [isVisibleSheetSendedEmail, setIsVisibleSheetSendedEmail] = useState<boolean>(false)

  const renderItems = useMemo(() => {
    if (data?.unavailableTitle && data?.unavailableMessage) {
      return (
        <ProductNotAvailable title={data.unavailableTitle} description={data.unavailableMessage} />
      )
    }

    if (yearWithoutIr) {
      return <ProductWithoutIr />
    }

    if (!demonstrative) {
      return <ProductNotAvailable />
    }

    if (demonstrative && !demonstrative?.retroative) {
      return (
        <Product
          choiceYear={choiceYear || ''}
          demonstrative={demonstrative}
          onFileDownload={(value: HomePfd) => setFileDownload(value)}
          onIsVisibleSheetDemonstrative={(value: boolean) => setIsVisibleSheetDemonstrative(value)}
        />
      )
    }

    if (demonstrative && demonstrative?.retroative) {
      return (
        <ProductRetrotive
          demonstrative={demonstrative}
          retroative={demonstrative.retroative}
          onFileDownload={(value: HomePfd) => setFileDownload(value)}
          onIsVisibleSheetDemonstrative={(value: boolean) => setIsVisibleSheetDemonstrative(value)}
        />
      )
    }

    return null
  }, [choiceYear, data?.unavailableMessage, data?.unavailableTitle, demonstrative, yearWithoutIr])

  return (
    <S.Container>
      {renderItems}

      {fileDownload && (
        <BottonSheetDemonstrative
          choiceYear={choiceYear!}
          isVisible={isVisibleSheetDemonstrative}
          onClose={() => setFileDownload(undefined)}
          onSendEmail={() => setIsVisibleSheetSendedEmail(true)}
          fileDownload={fileDownload}
          isRetroative={!!demonstrative?.retroative}
        />
      )}

      <BottonSheetDemonstrativeSendedEmail
        userEmail={user?.email || ''}
        demonstrativeYear={choiceYear || ''}
        isVisible={isVisibleSheetSendedEmail}
        onClose={() => setIsVisibleSheetSendedEmail(false)}
      />
    </S.Container>
  )
}
