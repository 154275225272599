import React from 'react'

import * as S from './styles'

interface IMemuItemProps {
  title: string
  onClick: () => void
}

export const MenuItem = ({ title, onClick }: IMemuItemProps) => (
  <S.Container onClick={onClick}>
    <S.Text>{title}</S.Text>
    <S.Icon />
  </S.Container>
)
