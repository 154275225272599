import styled, { keyframes } from 'styled-components'

import { Icon } from '@/components/IconComponent'
import * as GlobalStyles from '@/styles/styles'
import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const Container = styled.div``

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`
export const IconLoading = styled(Icon).attrs({
  name: 'loading',
  width: 24,
  height: 24,
  color: 'var(--primary500)',
})`
  animation: ${rotate} 1.1s infinite linear;
`

export const Text = styled(TextUI)``

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Space = styled(GlobalStyles.DefaultSpace)``
