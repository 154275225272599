import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Text = styled(TextUI)``

export const Contaioner = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid var(--gray200);
  }

  li:last-child {
    border: none !important;
  }
`

export const Space = styled(GlobalStyles.DefaultSpace)``
