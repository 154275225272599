import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { BridgeService } from '@/services'

type Link = {
  target: {
    className: string
    tagName: string
    getAttribute: (str: string) => string
  }
  stopPropagation: () => void
  preventDefault: () => void
}

const useEventLintener = (ref: React.RefObject<HTMLDivElement>) => {
  const history = useHistory()

  useEffect(() => {
    function eventListener(e: unknown) {
      const { target } = e as unknown as Link
      const dataUrl = target.getAttribute('data-url')
      if (target.className === 'open-deep-link') {
        BridgeService.navigateDeepLink(dataUrl)
        return
      }
      if (target.className === 'open-native-link') {
        BridgeService.openNativeBrowser(dataUrl)
        return
      }
      if (target.className === 'link-react-router-dom') {
        history.push(dataUrl)
      }
    }

    const element = ref.current

    if (element) {
      element.addEventListener('click', eventListener)
    }

    return () => {
      element?.removeEventListener('click', eventListener)
    }
  }, [ref])
}

export default useEventLintener
