import React from 'react'

import { Icon } from '@/components/IconComponent'
import { AppBar } from '@interco/inter-ui/components/AppBar'

import * as S from './styles'

export type HeaderProps = {
  onBackClick: () => void
  children?: React.ReactNode
  rightIcon?: string | React.ReactNode
  onRightButtonClick?: () => void
  showLeftIcon?: boolean
}

type RightIconProps = Pick<HeaderProps, 'rightIcon' | 'onRightButtonClick'>

const RightIcon = ({ rightIcon, onRightButtonClick }: RightIconProps): JSX.Element => {
  if (typeof rightIcon === 'string') {
    return (
      <Icon
        data-testid="home-icon"
        name={rightIcon}
        width={24}
        height={24}
        color="var(--primary500)"
        onClick={() => onRightButtonClick?.()}
      />
    )
  }

  if (rightIcon) return rightIcon as JSX.Element
  return <S.CleanIcon />
}

export const Header = ({
  onBackClick,
  rightIcon,
  children,
  onRightButtonClick,
  showLeftIcon = true,
}: HeaderProps) => (
  <AppBar
    leftIcon={
      showLeftIcon && (
        <S.IconLeftContainer>
          <Icon
            width={24}
            height={24}
            data-testid="left-arrow"
            name="left-arrow"
            color="var(--primary500)"
            onClick={onBackClick}
          />
        </S.IconLeftContainer>
      )
    }
    rightIcon={<RightIcon rightIcon={rightIcon} onRightButtonClick={onRightButtonClick} />}
  >
    <S.Title>{children}</S.Title>
  </AppBar>
)
