import React from 'react'

import * as S from './styles'

type Props = {
  size?: number
}

const Loading = ({ size = 45 }: Props) => (
  <S.LoadingWrapper>
    <S.LoadingIcon name="loading" width={size} height={size} color="var(--orange500)" />
  </S.LoadingWrapper>
)

export default Loading
