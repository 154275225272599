import { action } from 'cdpro-redux-utils'

import { IWbUserInfo } from '@interco/inter-webview-bridge'

import { Platforms } from './types'

export const Types = {
  User: {
    SET_USER: '@user/SET_USER',
  },
  Onboarding: {
    SET_ONBOARDING: '@Onboarding/SET_ONBOARDING',
  },
  App: {
    SET_TYPE: '@app/SET_TYPE',
  },
  MobilePlatform: {
    SET_MOBILE_PLATFORM: '@MobilePlatform/SET_MOBILE_PLATFORM',
  },
  BasicAccount: {
    SET_BASIC_ACCOUNT: '@BasicAccount/SET_BASIC_ACCOUNT',
  },
  Home: {
    SET_HOME: '@Home/SET_HOME',
  },
  Product: {
    SET_PRODUCT_DATA: '@Product/SET_PRODUCT_DATA',
    SET_PRODUCT_DETAIL: '@Product/SET_PRODUCT_DETAIL',
    SET_PRODUCT_DETAIL_EXPANDED: '@Product/SET_PRODUCT_DETAIL_EXPANDED',
  },
}

export const setProductData = action<{ products: GenericProductType }>(
  Types.Product.SET_PRODUCT_DATA,
)

export const setProductDetail = action<{ product: ProductDetail }>(Types.Product.SET_PRODUCT_DETAIL)
export const setProductDetailExpanded = action<{ product: ServiceItem }>(
  Types.Product.SET_PRODUCT_DETAIL_EXPANDED,
)

export const setUser = action<{ user: IWbUserInfo }>(Types.User.SET_USER)

export const setOnboarding = action<{ onboarding: Onboarding | undefined }>(
  Types.Onboarding.SET_ONBOARDING,
)

export const setAppType = action<{ appType: AppType }>(Types.App.SET_TYPE)

export const setMobilePlatform = action<Platforms>(Types.MobilePlatform.SET_MOBILE_PLATFORM)

export const setBasicAccount = action<boolean>(Types.BasicAccount.SET_BASIC_ACCOUNT)

export const setHome = action<Home | undefined>(Types.Home.SET_HOME)
