import styled, { css } from 'styled-components'

export const marginHorizontalDefault = 24

export const DefaultHeaderStyles = () => css`
  position: relative;
`

export const DefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;
`

export const DefaultSpace = styled.div<{ height?: number }>`
  height: ${(p) => (p.height ? `${p.height}px` : '8px')};
  width: 100%;
`

const marginH = (p: SeparatorProps) =>
  p.marginHorizontalDefault
    ? `${p.marginHorizontalDefault * 2}px`
    : `${marginHorizontalDefault * 2}px`

export const DefaultSeparator = styled.div<SeparatorProps>`
  background-color: var(--gray100);
  height: 8px;
  width: calc(100% + ${marginH});
  margin: 0
    ${(p) =>
      p.marginHorizontalDefault
        ? `-${p.marginHorizontalDefault}px`
        : `-${marginHorizontalDefault}px`}
    32px;
`
