import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const Container = styled.div`
  width: 100vw;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--neutral-theme);
  padding: 0 24px;
  top: 0;
  left: 0;
`

export const IconLeftContainer = styled.div`
  height: 24px;
  width: 24px;
`
export const Title = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h3',
  semiBold: true,
})`
  align-self: center;
  margin: auto;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100vw - 78px);
`

export const CleanIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: transparent;
`
