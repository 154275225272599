import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const TextLine = styled(TextUI)`
  display: flex;
`

export const TrHeader = styled.tr`
  height: 23px;
  th span {
    float: right;
  }
  th:first-child span {
    float: left;
  }
  position: relative;
  &::after {
    content: '';
    left: -24px;
    top: 23px;
    border-bottom: 1px solid #dedfe4;
    position: absolute;
    width: 100vw;
  }
`

export const TrSpace = styled.tr`
  height: 16px;
`
export const TrRows = styled.tr`
  height: 23px;
  td span {
    float: right;
  }
  td:first-child span {
    float: left;
  }
  td {
    padding: 4px 8px !important;
  }
  background: var(--gray100);
  &:nth-of-type(even) {
    background: var(--neutral-theme);
  }
`
export const TrRowsDoted = styled(TrRows)`
  border-bottom: 1px dashed #dedfe4;
`
export const Table = styled.table`
  border-collapse: collapse;
  margin-top: 8px;
  width: 100%;
`
