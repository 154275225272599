/* istanbul ignore file */
import React, { useCallback, useEffect } from 'react'

import ReactDOM from 'react-dom'

import { interWbSession } from '@interco/inter-webview-bridge'
import { IS_DEVELOPMENT } from '@/common/constants'
import { enableDarkMode } from '@interco/inter-ui'

import { inApp } from './services/bridge'
import AppProviders from './contexts'
import Routes from './routes'

import './styles/global.css'

if (IS_DEVELOPMENT) {
  const { worker } = require('@/common/mocks/handlers')

  worker().start()
}

const App = () => {
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()

    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
    }
  }, [])
  useEffect(() => {
    if (inApp) getAppInfo()
  }, [getAppInfo])

  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)
