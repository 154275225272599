import React, { useRef, useState } from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { Text } from '@interco/inter-ui/components/Text'
import { Accordion, BoxInfo, GuideSteps } from '@/components'
import { formatMarkdownToLink } from '@/utils/string/format'
import useEventLintener from '@/hooks/useEventListener'

import * as S from './styles'

export type StepsOptions = ConsortiumOptions | VariableIncomeStepsOptions

export interface IStepProps {
  title: string
  step: number
  subtitle: string
  steps: Array<Partial<StepsOptions>>
  stepByStep: StepInfo[]
  important?: VariableIncomeImportant
  onShowBottonSheet?: () => void
}

export const Step = ({
  title,
  subtitle,
  step,
  steps,
  stepByStep,
  important,
  onShowBottonSheet,
}: IStepProps) => {
  const [expanded, setExpanded] = useState('')
  const refCt = useRef<HTMLDivElement>(null)

  useEventLintener(refCt)

  return (
    <S.Container>
      <S.Text variant="body-3" bold>
        Passo {step}
      </S.Text>

      <S.Space />

      <S.Text variant="headline-h3" semiBold>
        {title}
      </S.Text>

      <S.Space />

      <S.Text variant="body-3">
        <ReactMarkdown>{subtitle}</ReactMarkdown>
      </S.Text>

      <S.Space />
      <S.ContainerAccordion ref={refCt}>
        {steps &&
          steps.map((stepData) => (
            <Accordion
              id={`${stepData.id}`}
              setExpanded={setExpanded}
              expanded={expanded}
              title={stepData.text as string}
              description={
                stepByStep ? stepByStep.find((desc) => desc.id === stepData.id)?.description : ''
              }
            >
              <S.AccordionContentContainer>
                {stepData && (
                  <GuideSteps
                    steps={stepByStep
                      .filter((stepContent) => stepContent.id === stepData.id)
                      .reduce(
                        (stepDataContent, itemContent) => [
                          ...stepDataContent,
                          ...itemContent.content,
                        ],
                        [] as StepContent[],
                      )
                      .map((content) => ({
                        title: (
                          <S.ContentTitle>
                            <Text
                              variant="body-3"
                              colorWeight={500}
                              bold={content && !!(content.description && content.text)}
                            >
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                {content.text ? formatMarkdownToLink(content.text) : ''}
                              </ReactMarkdown>
                            </Text>
                            {content.rightIcon && onShowBottonSheet && (
                              <S.ContentIconHelp onClick={onShowBottonSheet}>
                                <S.IconHelp />
                              </S.ContentIconHelp>
                            )}
                          </S.ContentTitle>
                        ),
                        description: (
                          <Text variant="body-3">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                              {content.description ? formatMarkdownToLink(content.description) : ''}
                            </ReactMarkdown>
                          </Text>
                        ),
                      }))}
                  />
                )}
                <S.Space height={24} />
                {step === 4 && (
                  <BoxInfo
                    title={important?.title || ''}
                    description={important?.description || ''}
                  />
                )}
              </S.AccordionContentContainer>
            </Accordion>
          ))}
      </S.ContainerAccordion>
    </S.Container>
  )
}
