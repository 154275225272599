import React from 'react'

import { formatDate } from '@/utils/dates'

import { BlogItem } from './components'
import * as S from './styles'

export interface IBlogProps {
  blogList: BlogHit[]
}

export const BlogList = ({ blogList }: IBlogProps) => (
  <S.Container>
    <S.Space height={24} />
    <S.ContentBlogList>
      {blogList.map((blog) => (
        <BlogItem
          key={blog.id}
          title={blog.fields.title}
          introduction={blog.fields.introduction}
          createdAt={formatDate(blog.fields.created_at)}
          image={blog.fields.grid_image_url}
          slug={blog.fields.slug}
        />
      ))}
    </S.ContentBlogList>
  </S.Container>
)
