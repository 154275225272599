import styled from 'styled-components'

import { Icon as IconUI } from '@/components/IconComponent'
import { Button as ButtonUI } from '@interco/inter-ui/components/Button'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Icon = styled(IconUI)``
export const Space = styled(GlobalStyles.DefaultSpace)``
export const Text = styled(TextUI).attrs({
  variant: 'body-3',
})`
  word-break: break-all;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const List = styled.ul`
  margin: 0;
  list-style: none;

  li {
    border-bottom: 1px dashed var(--gray200);
  }
  li:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`

export const ListItem = styled.li`
  padding-bottom: 20px;
  margin-bottom: 18px;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const ContentFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 12px;
  }
`

export const IconReceipt = styled(Icon).attrs({
  height: 24,
  width: 24,
  name: 'receipt',
  color: 'var(--gray500)',
})``

export const Button = styled(ButtonUI)`
  width: 100%;
  margin-top: 16px;
`
