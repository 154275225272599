import { rest, setupWorker } from 'msw'

import * as ENDPOINTS from '@/common/constants/endpoints'
import * as MOCKS from '@/common/mocks/responses'

const DEFAULT_DELAY = 500
const SUCCESS_STATUS = 200

export const EndPoints = [
  rest.get(`/${ENDPOINTS.ONBOARDING}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.Onboarding),
    ),
  ),
  rest.put(`/${ENDPOINTS.ONBOARDING}`, (_request, response, context) =>
    response(context.delay(DEFAULT_DELAY), context.status(SUCCESS_STATUS)),
  ),
  rest.get(`/${ENDPOINTS.HOME}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.Home),
    ),
  ),
  rest.get(`/${ENDPOINTS.BLOG}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.Blogs),
    ),
  ),
  rest.get(`/${ENDPOINTS.DECLARATION_FACILITATED}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.DeclarationFacilitated),
    ),
  ),
  rest.get(`/${ENDPOINTS.PENSION}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.Pension),
    ),
  ),
  rest.get(`/${ENDPOINTS.CONSORTIUM}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.Consortium),
    ),
  ),
  rest.get(`/${ENDPOINTS.VARIABLE_INCOME}`, (_resquest, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json(MOCKS.VariableIncome),
    ),
  ),
]

export const worker = () => setupWorker(...EndPoints)
