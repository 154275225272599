import React from 'react'

import { BridgeService } from '@/services'

import * as S from './styles'

export const DiscoverOurTools = ({ title, description, content }: VariableIncomeFooter) => {
  const handleNavigation = (deeplink: string) => {
    BridgeService.navigateDeepLink(deeplink)
  }

  return (
    <S.Container>
      <S.Text variant="body-3" bold colorWeight={500}>
        {title}
      </S.Text>
      <S.Space />
      <S.Text variant="body-3">{description}</S.Text>
      <S.Space height={24} />
      <S.List>
        {content.map((item) => (
          <S.ListItem onClick={() => handleNavigation(item.url)}>
            <S.ContentImage>
              <S.Image url={item.urlImage} />
            </S.ContentImage>
            <S.ListItemInfo>
              <S.Text variant="body-3" bold colorWeight={500}>
                {item.title}
              </S.Text>
              <S.Space />
              <S.Text variant="caption-1">{item.description}</S.Text>
            </S.ListItemInfo>
            <S.IconChevronRight />
          </S.ListItem>
        ))}
      </S.List>
    </S.Container>
  )
}
