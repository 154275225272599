import styled from 'styled-components'

import { Icon as IconUI } from '@/components/IconComponent'
import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const Container = styled.li`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li:last-child {
    border: none !important;
  }
`

export const Text = styled(TextUI).attrs({
  variant: 'caption-1',
  colorWeight: 500,
})``

export const Icon = styled(IconUI).attrs({
  name: 'chevron-right',
  width: 24,
  height: 24,
  color: 'var(--primary500)',
})`
  margin-right: -8px;
`
