import styled from 'styled-components'

import { Text as UIText } from '@interco/inter-ui/components/Text'
import { Icon } from '@/components/IconComponent'

const CIRCLE_SIZE = 24

export const Container = styled.div``

export const Text = styled(UIText)``

export const StepContainer = styled.div`
  padding: 16px 0 24px 0;
  border-bottom: 1px dashed var(--gray200);

  display: grid;
  grid-template-columns: 1fr 34px;
  justify-content: space-between;

  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }
`

export const StepContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  a {
    color: var(--primary500);
    text-decoration: none;
    font-weight: bold;
  }
  > span:last-of-type {
    margin-top: 4px;
  }
`

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray500);
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  color: var(--neutral-theme);
  border-radius: var(--radiiXlg);
  font-size: 12px;
`

export const Divider = styled.div`
  border: 1px dashed #dedfe4;
  display: block;
`

export const Description = styled.div`
  margin-bottom: 16px;
`

export const ContentArrow = styled.div``
export const IconArrowRight = styled(Icon).attrs({
  height: 24,
  width: 24,
  name: 'chevron-right',
})``
