import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Header, MenuList, LoadingSkeleton, ShowError } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { ApiService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { setProductData, setProductDetail } from '@/contexts/global-state/reducer/actions'

import * as S from './styles'
import { PartnerProducts } from './components'

interface IHistoryState {
  choiceYear: string
}

export const DeclarationFacilitated = () => {
  const history = useHistory()

  const location = useLocation<IHistoryState>()
  const [{ user, accountType, home }, dispatch] = useGlobalState()
  const [hasError, setHasError] = useState({
    general: false,
  })
  const { isFetching, data } = useQuery<DeclarationFacilitated | undefined>(
    'declarationFacilitated',
    async () =>
      ApiService.DeclarationFacilitated.getDeclarationFacilitated({
        // ssn: '71023465906',
        ssn: user?.cpf || '',
        year: location?.state?.choiceYear || (home?.choiceYear as string),
        account: user?.account as string,
        accountType,
        quarter: home?.data?.choiceBasePeriod || '',
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (val) => {
        if (val && val?.newProducts) {
          dispatch(
            setProductData({
              products: val?.newProducts,
            }),
          )
        } else {
          dispatch(
            setProductData({
              products: { groups: [], products: [] },
            }),
          )
        }
        if (home?.data?.accountType !== 'PF') {
          const product = val?.products[0]
          if (product !== undefined) {
            dispatch(
              setProductDetail({
                product: {
                  productKey: product.productKey,
                  pageTitle: product.metadata.title,
                  formDescription: product.metadata.description,
                  services: product.services,
                },
              }),
            )
            history.replace({
              pathname: RoutesNames.PRODUCT_DETAIL,
            })
          }
        }
      },
      onError: (error) => {
        setHasError({
          general: true,
        })

        return error
      },
    },
  )

  if (isFetching) {
    return (
      <S.ContainerLoading>
        <LoadingSkeleton />
      </S.ContainerLoading>
    )
  }
  if (hasError.general) return <ShowError />
  return (
    <S.Container>
      <Header
        onRightButtonClick={() => history.push(RoutesNames.ONBOARDING)}
        rightIcon="help"
        onBackClick={() => history.push(RoutesNames.HOME)}
      >
        {data?.metadata?.headerTitle}
      </Header>
      <S.Space height={24} />
      <S.Text variant="headline-h2" colorWeight={500} semiBold>
        {data?.metadata?.title}
      </S.Text>
      <S.Space />
      <S.Text variant="body-3">{data?.metadata?.description}</S.Text>
      <S.Space height={32} />
      <S.Sepator />
      {data && data?.metadata && (
        <MenuList
          title={data.metadata.subtitle}
          subtitle={data.metadata.complement}
          data={data}
          handleNavigation={(productDetail: ProductDetail) => {
            dispatch(setProductDetail({ product: productDetail }))

            history.push({
              pathname: RoutesNames.PRODUCT_DETAIL,
            })
          }}
        />
      )}
      {data && data.metadata && data.metadata.partnerProducts?.options?.length > 0 && (
        <>
          <S.Space height={32} />
          <S.Sepator />
          <PartnerProducts
            title={data.metadata.partnerProducts.title}
            description={data.metadata.partnerProducts.description}
            options={data.metadata.partnerProducts.options}
          />
        </>
      )}
    </S.Container>
  )
}
