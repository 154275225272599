import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Separator = styled.div`
  background-color: var(--gray100);
  height: 8px;
  margin: 0 -24px;
`

export const Text = styled(TextUI)``

export const ContainerLoading = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`
