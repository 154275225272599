import React, { useRef } from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { formatMarkdownToLink } from '@/utils/string/format'
import useEventLintener from '@/hooks/useEventListener'

import { SectionTitle } from '..'

import * as S from './styles'

// interface IproductCodes {
//   code: string
//   description: string
// }
interface IBottonSheetServiceProps {
  serviceName: string
  instruction: string
  // productCodes: Array<IproductCodes>
  isVisible: boolean
  onClose: () => void
}

export const BottonSheetService = ({
  serviceName,
  instruction,
  // productCodes,
  isVisible,
  onClose,
}: IBottonSheetServiceProps) => {
  const refCt = useRef<HTMLDivElement>(null)

  useEventLintener(refCt)
  if (!isVisible) return null

  return (
    <>
      <S.BottomSheet onClose={onClose}>
        <S.Container ref={refCt}>
          <SectionTitle>{serviceName}</SectionTitle>
          <S.Space height={12} />
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {formatMarkdownToLink(instruction)}
          </ReactMarkdown>
        </S.Container>
        <S.Space height={12} />
      </S.BottomSheet>
    </>
  )
}
