import styled from 'styled-components'

import * as GlobalStyles from '@/styles/styles'
import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Text = styled(TextUI)``

export const Separator = styled(GlobalStyles.DefaultSeparator)`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const Box = styled.div`
  text-align: center;

  svg {
    max-width: 100%;
  }
`
