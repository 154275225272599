import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { Icon } from '@/components/IconComponent'
import { BottonSheet, ProductComponet } from '@/new-components'

import * as S from './styles'

export const GenericDetailPage = () => {
  const history = useHistory()
  const location = useLocation()
  const [bottomSheetData, setBottomSheetData] = useState<BottomSheet>()
  const details = location.state as SectionWithButtonAndNewPageDetail
  return (
    <S.Container>
      <AppBar
        leftIcon={
          <Icon
            color="var(--primary500)"
            width={24}
            height={24}
            name="leftarrow"
            onClick={() => history.goBack()}
          />
        }
      >
        {details.title}
      </AppBar>
      {details.productDetails.map((item) => (
        <ProductComponet
          {...item}
          openBottomSheet={(data) => {
            setBottomSheetData(data)
          }}
        />
      ))}
      {bottomSheetData && (
        <BottonSheet
          {...bottomSheetData}
          isVisible={bottomSheetData !== undefined}
          onClose={() => setBottomSheetData(undefined)}
        />
      )}
    </S.Container>
  )
}
