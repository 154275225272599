import styled from 'styled-components'

import { BottomSheet as BottomSheetUI } from '@interco/inter-ui/components/BottomSheet'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const BottomSheet = styled(BottomSheetUI)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0 0;
`

export const Text = styled(TextUI)``

export const Space = styled(GlobalStyles.DefaultSpace)``

export const List = styled.ul`
  list-style: none;
`
export const ListItem = styled.li``
