import styled from 'styled-components'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Button as ButtonUI } from '@interco/inter-ui/components/Button'

export const ColumnFlex = styled(Flex).attrs({
  justifyContent: 'flex-start',
  direction: 'column',
  alignContent: 'flex-start',
  alignItems: 'baseline',
})`
  width: 100%;
`

export const Button = styled(ButtonUI)`
  width: 100%;
`
