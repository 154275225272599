import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

import { Icon } from '../IconComponent'

export const Container = styled.div`
  width: 100%;
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Sepator = styled(GlobalStyles.DefaultSeparator)``

export const Text = styled(TextUI)``

export const ContainerAccordion = styled.div`
  > div {
    border-bottom: 1px solid var(--gray200);
  }
  > div:last-child {
    border-bottom: 0;
  }
`

export const AccordionContentContainer = styled.div`
  padding-top: 28px;

  a,
  button {
    color: var(--primary500);
    text-decoration: none;
    font-weight: bold;
    border: none;
    background: transparent;
    outline: none;
  }
`

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: normal;
  color: var(--typography400);
  margin-bottom: 10px;

  p {
    flex: 1;
  }
`

export const ContentIconHelp = styled.div``
export const IconHelp = styled(Icon).attrs({
  width: 18,
  height: 18,
  name: 'help',
})``
