import React from 'react'

import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import routes, { RoutesNames } from './routes'

const Routes = () => (
  <Router basename={RoutesNames.ROOT} hashType="noslash">
    <Switch>
      {routes.map((route) => (
        <Route {...route} key={route.path} />
      ))}
    </Switch>
  </Router>
)

export default Routes
