import React from 'react'

import Info from '@interco/icons/orangeds/SM/info'
import ArrowLeft from '@interco/icons/orangeds/SM/arrow-left'
import Home from '@interco/icons/orangeds/SM/home'
import Loading from '@interco/icons/orangeds/MD/loading'
import ArrowRight from '@interco/icons/orangeds/MD/arrow-right'
import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import ChevronUp from '@interco/icons/orangeds/MD/chevron-up'
import ChevronDown from '@interco/icons/orangeds/MD/chevron-down'
import Help from '@interco/icons/orangeds/XL/help'
import Download from '@interco/icons/orangeds/MD/download'
import Share from '@interco/icons/orangeds/MD/share'
import Email from '@interco/icons/orangeds/MD/email'
import Copy from '@interco/icons/orangeds/MD/copy'
import Receipt from '@interco/icons/orangeds/MD/receipt'
import Check from '@interco/icons/orangeds/MD/check'

export const Icons: Record<string, (props: IconProps) => JSX.Element | null> = {
  info: (props: IconProps) => <Info data-testid="info" {...props} />,
  leftarrow: (props: IconProps) => <ArrowLeft data-testid="leftarrow" {...props} />,
  home: (props: IconProps) => <Home data-testid="home" {...props} />,
  loading: (props: IconProps) => <Loading data-testid="loading" {...props} />,
  arrowright: (props: IconProps) => <ArrowRight data-testid="arrowright" {...props} />,
  chevronright: (props: IconProps) => <ChevronRight data-testid="chevronright" {...props} />,
  chevronup: (props: IconProps) => <ChevronUp data-testid="chevronup" {...props} />,
  chevrondown: (props: IconProps) => <ChevronDown data-testid="chevrondown" {...props} />,
  help: (props: IconProps) => <Help data-testid="help" {...props} />,
  download: (props: IconProps) => <Download data-testid="download" {...props} />,
  share: (props: IconProps) => <Share data-testid="share" {...props} />,
  email: (props: IconProps) => <Email data-testid="email" {...props} />,
  copy: (props: IconProps) => <Copy data-testid="copy" {...props} />,
  receipt: (props: IconProps) => <Receipt data-testid="receipt" {...props} />,
  check: (props: IconProps) => <Check data-testid="check" {...props} />,
}

export interface IconProps {
  color?: string
  name: string
  width?: number
  height?: number
  onClick?: () => void
}

export const Icon = (props: IconProps) => {
  const { name, color, width, height, onClick } = props
  const IconComponent = Icons[name.toLowerCase().replace(/[-]/g, '')]
  if (IconComponent) return IconComponent({ ...props, name, color, width, height, onClick })
  return <span data-testid="no-icon" />
}
