export const shouldInsertAccount = ({
  account,
}: {
  accountType: AccountType
  account: string
}) => ({
  ...{
    'x-inter-conta-corrente': account,
  },
})
