import React from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { SectionTitle } from '../../components'
import * as S from './styles'

export interface IBottonSheetServiceProps {
  isVisible: boolean
  onClose: () => void
}

export const BottonSheet = ({
  title,
  description,
  isVisible,
  onClose,
}: IBottonSheetServiceProps & BottomSheet) => {
  if (!isVisible) return null

  return (
    <>
      <S.BottomSheet onClose={onClose}>
        <S.Container>
          <SectionTitle>{title}</SectionTitle>
          <S.Space height={12} />
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
        </S.Container>
        <S.Space height={12} />
      </S.BottomSheet>
    </>
  )
}
