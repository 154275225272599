import React from 'react'

type Props = {
  target: {
    current: HTMLButtonElement | null
  }
  onIntersect: () => void
  enabled: boolean
  threshold?: number
  rootMargin?: string
}

export default function useIntersectionObserver({
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: Props) {
  React.useEffect(() => {
    if (!enabled) {
      return undefined
    }

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        rootMargin,
        threshold,
      },
    )

    const el = target && target.current

    if (!el) {
      return undefined
    }

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [target.current, enabled])
}
