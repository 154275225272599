import React from 'react'

import { useHistory } from 'react-router-dom'

import { BridgeService } from '@/services'
import { MainError } from '@/assets/images/main-error'
import { RoutesNames } from '@/routes/routes'
import { AppBar } from '@interco/inter-ui/components/AppBar'

import * as S from './styles'
import { Icon } from '../IconComponent'

export const ShowError = () => {
  const history = useHistory()
  return (
    <S.ContainerError data-testid="error-page">
      <AppBar
        leftIcon={
          <Icon
            data-testid="left-arrow-button"
            color="var(--primary500)"
            name="leftarrow"
            width={24}
            height={24}
            onClick={() => BridgeService.interWbNavigate.requestGoBack()}
          />
        }
      />
      <S.ContentError>
        <MainError />
        <S.Space height={24} />
        <S.Text variant="headline-h1">Houve um erro por aqui</S.Text>
        <S.Space />
        <S.Text variant="body-3">
          Funcionalidade temporariamente indisponível. Por favor, tente novamente em alguns
          instantes.
        </S.Text>
        <S.Space height={24} />
      </S.ContentError>
      <S.Button
        data-testid="try-again"
        onClick={() =>
          history.push({
            pathname: RoutesNames.ROOT,
          })
        }
      >
        Tentar novamente
      </S.Button>
    </S.ContainerError>
  )
}
