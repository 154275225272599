import styled from 'styled-components'

import { Button as ButtonUI } from '@interco/inter-ui/components/Button'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const ContainerError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 24px 24px 32px;

  > button {
    width: 100%;
  }
`
export const ContentError = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;

  > button {
    width: 100%;
  }
`

export const Text = styled(TextUI)``

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Button = styled(ButtonUI)``
