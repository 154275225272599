import React, { useCallback } from 'react'

import { requestAnalytics } from '@/services/bridge'
import { BridgeService } from '@/services'
// import anteciparBannerImage from '@/assets/images/IR_widget_digital_teste_antecipacaoir.png'

import * as S from './styles'

import { SectionTitle } from '..'

interface IBanners {
  imageUrl: string
  onClickAction: () => void
}
interface IMakeYourself {
  explicativeContents: ExplicativeContents[]
  // accountType?: string
}
export const MakeYourself = ({ explicativeContents }: IMakeYourself) => {
  const handleAction = useCallback((url: string) => {
    requestAnalytics('CliqueConteudos')
    BridgeService.openNativeBrowser(url)
  }, [])

  const banners: IBanners[] = []
  /* if (accountType === 'PF') {
    banners.push({
      imageUrl: anteciparBannerImage,
      onClickAction: () =>
        BridgeService.navigateDeepLink(
          'bancointer://creditoPessoal?fluxo=antecipacao_imposto_renda&utm_source=widget&utm_medium=crm&utm_campaign=informederendimentos&utm_content=anteciparmeuIR',
        ),
    })
  } */
  banners.push(
    ...(explicativeContents?.map((item) => ({
      imageUrl: item.image,
      onClickAction: () => handleAction(item.OnClick),
    })) || []),
  )
  return (
    <S.Container>
      <SectionTitle>Conteúdos explicativos</SectionTitle>
      <S.Space height={24} />
      <S.Carousel
        dots
        arrows={false}
        infinite={false}
        autoplay
        speed={500}
        slidesToShow={1}
        dotsType="big"
        verticalMode={false}
      >
        {banners?.map(({ imageUrl, onClickAction }) => (
          <S.ContentImage onClick={onClickAction} key={imageUrl}>
            <S.Link>
              <S.Image url={imageUrl} />
            </S.Link>
          </S.ContentImage>
        ))}
      </S.Carousel>
    </S.Container>
  )
}
