import styled from 'styled-components'

import { Button as ButtonUI } from '@interco/inter-ui/components/Button'
import { Checkbox as CheckboxUI } from '@interco/inter-ui/components/Checkbox'
import { Icon as IconUI } from '@/components/IconComponent'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const DemonstrativeInfo = styled(TextUI).attrs({
  variant: 'caption-2',
  color: 'typography',
  colorWeight: 500,
})`
  font-weight: 700;
  padding: 2px 4px;
  border-radius: 4px;
  align-self: flex-start;
`

export const DemonstrativeInfoEnabled = styled(DemonstrativeInfo)`
  color: var(--success500);
  background-color: var(--success100);
`

export const DemonstrativeInfoDisabled = styled(DemonstrativeInfo)`
  color: #5d4037;
  background-color: #efebe9;
`

export const Icon = styled(IconUI)``

export const Text = styled(TextUI).attrs({
  colorWeight: 500,
  variant: 'body-3',
})``

export const List = styled.ul`
  margin: 0;
  padding: 0 0 0 24px;
`

export const ListItem = styled.li`
  margin-top: 8px;
  font-size: 12px;
`

export const Button = styled(ButtonUI)`
  width: 100%;
  margin-top: 16px;
`

export const ListCheckbox = styled.ul`
  margin: 0;
  list-style: none;

  li {
    border-bottom: 1px dashed var(--gray200);
  }
  li:last-child {
    border-bottom: 0;
  }
`

export const ListItemCheckbox = styled.li`
  padding: 10px 0;
  text-align: start;

  span:first-child {
    margin-bottom: 4px;
  }
`

interface ICheckboxChecked {
  isChecked: boolean
}

export const Checkbox = styled(CheckboxUI).attrs<ICheckboxChecked>(({ isChecked }) => ({
  color: isChecked ? 'var(--primary500)' : 'var(--gray300)',
}))<ICheckboxChecked>``

export const CheckboxLabel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const ContentFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 0;

  svg {
    margin-right: 12px;
  }
`

export const IconReceipt = styled(Icon).attrs({
  height: 24,
  width: 24,
  name: 'receipt',
  color: 'var(--gray500)',
})``
