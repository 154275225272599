const BASE_URL_BFF = 'informe-rendimentos/v1'
const BASE_URL_GED = 'informe-rendimentos/v1'
const BASE_URL_BLOG = 'https://crm-api.web.bancointer.com.br'

export const HOME = `${BASE_URL_BFF}/home`
export const PENSION = `${BASE_URL_BFF}/pension`
export const DOCUMENT = `${BASE_URL_BFF}/document`
export const CONSORTIUM = `${BASE_URL_BFF}/consortium`
export const ONBOARDING = `${BASE_URL_BFF}/onboarding`
export const VARIABLE_INCOME = `${BASE_URL_BFF}/variable-income`
export const DECLARATION_FACILITATED = `${BASE_URL_BFF}/earnings-report`

export const SEND_EMAIL = `${BASE_URL_GED}/email`

export const BLOG = `${BASE_URL_BLOG}/api/blog/search?q=`
