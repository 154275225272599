import React, { useState } from 'react'

import { Accordion } from '@/components'

import * as S from './styles'
import { PensionCardNav } from '../pension-card-nav'

interface PensionStepNavProps {
  options?: PensionStepsOptions[]
  pension?: PensionPension[]
}

export const PensionStepNav = (props: PensionStepNavProps) => {
  const { options, pension } = props
  const [expanded, setExpanded] = useState('')

  if (!options) {
    return <></>
  }

  return (
    <div>
      {options.map((option) => (
        <Accordion
          key={option.id}
          id={`${option.id}`}
          expanded={expanded}
          setExpanded={setExpanded}
          title={option.text}
        >
          <S.AccordionContentContainer>
            {pension && <PensionCardNav pension={pension.find((item) => item.id === option.id)} />}
          </S.AccordionContentContainer>
        </Accordion>
      ))}
    </div>
  )
}
