import React, { useState, MouseEvent, useMemo } from 'react'

import { Flex } from '@interco/inter-ui/components/Flex'
import { ProductComponet } from '@/new-components/product'
import { Accordion } from '@/components'
import { Icon } from '@/components/IconComponent'

import * as S from './styles'

import { BottonSheet } from '..'

export interface SubGroupComponentProps extends SubGroup {
  idExpandedSection: string
  setIdExpandedSection: (str: string) => void
}

export const SubGroupComponent = ({
  title,
  action,
  idExpandedSection,
  setIdExpandedSection,
  productDetails,
  id,
}: SubGroupComponentProps) => {
  const [bottomSheetData, setBottomSheetData] = useState<BottomSheet>()

  const TitleIcon = useMemo(() => {
    if ((action as ActionBottomSheet)?.bottomSheet) {
      const { bottomSheet, icon } = action as ActionBottomSheet
      return (
        <S.ContainerIconClick
          data-testid="icon-bottomSheet"
          onClick={(event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setBottomSheetData(bottomSheet)
          }}
        >
          <Icon name={icon.id} color={`var(${icon.color})`} width={18} height={18} />
        </S.ContainerIconClick>
      )
    }

    return <span data-testid="no-action" />
  }, [action])
  return (
    <S.Container>
      <Accordion
        id={id}
        expanded={idExpandedSection}
        setExpanded={setIdExpandedSection}
        title={title}
        titleIcon={TitleIcon}
      >
        <Flex
          justifyContent="flex-start"
          direction="column"
          alignContent="flex-start"
          alignItems="baseline"
          gap="24px"
        >
          {productDetails.map((product, index) => [
            <ProductComponet
              {...product}
              openBottomSheet={(data) => {
                setBottomSheetData(data)
              }}
            />,
            index + 1 !== productDetails.length ? (
              <S.RowItemSeparator data-testid="row-separator" />
            ) : null,
          ])}
        </Flex>
      </Accordion>

      {bottomSheetData && (
        <BottonSheet
          {...bottomSheetData}
          isVisible={bottomSheetData !== undefined}
          onClose={() => setBottomSheetData(undefined)}
        />
      )}
    </S.Container>
  )
}
