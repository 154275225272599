import React, { createContext, ReactNode, useState } from 'react'

interface PensionProviderProps {
  children: ReactNode
}

export interface PensionContextData {
  handleSelectPage: (pension: PensionPensionOptions | undefined) => void
  pageOption: PensionPensionOptions | undefined
}

export const PensionContext = createContext({} as PensionContextData)
export function PensionProvider(props: PensionProviderProps) {
  const { children } = props

  const [pageOption, setPageOption] = useState<PensionPensionOptions | undefined>()

  const handleSelectPage = (pensionOptions: PensionPensionOptions | undefined) => {
    setPageOption(pensionOptions)
  }

  return (
    <PensionContext.Provider
      value={{
        handleSelectPage,
        pageOption,
      }}
    >
      {children}
    </PensionContext.Provider>
  )
}
