import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { DeclarationFacilitated } from '..'

import { api } from '../api'

interface IHeaderProps extends DefaultHeader {
  ssn: string
  year: string
  quarter: string
}

export const getDeclarationFacilitated = async ({
  account,
  accountType,
  ssn,
  year,
  quarter,
}: IHeaderProps): Promise<DeclarationFacilitated | undefined> => {
  const data: DeclarationFacilitated = {
    metadata: undefined,
    products: [],
    newProducts: undefined,
  }

  const response = await api<DeclarationFacilitatedApi>(ENDPOINTS.DECLARATION_FACILITATED, {
    method: 'GET',
    headers: {
      ssn,
      year,
      quarter,
      'account-type': accountType,
      ...shouldInsertAccount({ accountType, account }),
    },
  })

  if (!response) return undefined

  // Insert Metadata on Root object
  data.metadata = response.metadata
  data.newProducts = response.data

  // Insert Services on Products
  const products: Product[] = []
  Object.keys(response)
    .filter((key) => key !== 'metadata' && key !== 'data')
    .filter(Boolean)
    .forEach((key) => {
      const productKey = key as ProductKey
      const productData = response[productKey] as ProductOR
      const product: Product = {
        productKey,
        metadata: productData.metadata,
        services: [],
      }

      const services = Object.keys(productData)
        .filter((serviceKey) => serviceKey !== 'metadata')
        .map((serviceKey) => {
          const serviceItem = productData[serviceKey as keyof ProductOR]

          return [
            {
              productKey,
              serviceKey: serviceKey as ServiceKey,
              serviceName: (serviceItem as unknown as ServiceData).metadata?.type || '',
              serviceTitle: (serviceItem as unknown as ServiceData).metadata?.title || '',
              metadata: (serviceItem as unknown as ServiceData).metadata,
              data: (serviceItem as unknown as ServiceData).data,
            },
          ]
        })
        .flat()
        .filter(Boolean)

      product.services = services
      products.push(product)
    })

  data.products = products

  return data
}
