import React, { useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { DeclarationFacilitatedBanner } from '@/assets/images'
import { Header, Skeletons } from '@/components'
import { RoutesNames } from '@/routes/routes'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'
import { ApiService, BridgeService } from '@/services'
import * as Actions from '@/contexts/global-state/reducer/actions'
import { Icon } from '@/components/IconComponent'

import * as S from './styles'

interface DescriptionProps {
  title: string
  description: React.ReactNode
}

const Description = ({ title, description }: DescriptionProps) => (
  <S.ContentDescription>
    <S.DescriptionTitle>
      <Icon name="check" width={24} height={24} color="var(--gray500)" />
      <Text variant="body-3" bold colorWeight={500}>
        {title}
      </Text>
    </S.DescriptionTitle>
    <S.Text variant="body-3">{description}</S.Text>
  </S.ContentDescription>
)

export const OnBoarding = () => {
  const history = useHistory()
  const [{ onboarding, user, accountType }, dispatch] = useGlobalState()

  const { isFetching } = useQuery<Onboarding | undefined>(
    'getOnboarding',
    async () =>
      ApiService.Onboarding.getOnboarding({
        account: user?.account as string,
        accountType,
      }),
    {
      enabled: onboarding === undefined,
      refetchOnWindowFocus: false,

      onSuccess: (response) => {
        if (response) {
          dispatch(Actions.setOnboarding({ onboarding: response }))
        }
      },

      onError: () => {
        dispatch(
          Actions.setOnboarding({
            onboarding: undefined,
          }),
        )
        history.push(RoutesNames.ROOT, { replace: true })
      },
    },
  )

  const handleNavigate = useCallback(() => {
    //

    if (history.location.pathname === RoutesNames.ONBOARDING_FIRST_PAGE) {
      BridgeService.interWbNavigate.requestGoBack()
    } else {
      history.goBack()
    }
  }, [history])

  const handleAccept = useCallback(() => {
    BridgeService.setOnboarding(true)
    requestAnalytics('ConferirPassoAPasso')
    if (history.location.pathname === RoutesNames.ONBOARDING_FIRST_PAGE) {
      history.push(RoutesNames.HOME)
    } else {
      history.goBack()
    }
  }, [history])

  if (isFetching) return <Skeletons />
  if (!onboarding) return null
  return (
    <S.Container role="main" data-testid="container-onboarding">
      <Header onRightButtonClick={() => null} onBackClick={handleNavigate} />

      <DeclarationFacilitatedBanner />

      <S.Space height={24} />

      <S.Text variant="headline-h1" colorWeight={500} semiBold>
        {onboarding.title}
      </S.Text>

      <S.Space height={12} />

      <S.Text variant="body-3">{onboarding.description}</S.Text>

      <S.Space height={24} />

      {onboarding.contents.map((item) => (
        <Description
          key={item.contentTitle}
          title={item.contentTitle}
          description={item.contentDescription}
        />
      ))}

      <S.Footer>
        <Button
          onClick={handleAccept}
          icon={<S.Icon name="arrow-right" color="white" />}
          variant="primary"
        >
          {onboarding.button.text}
        </Button>
      </S.Footer>
    </S.Container>
  )
}
