import * as ENDPOINTS from '@/common/constants/endpoints'

interface IGetBlogsProps {
  size: number
  page: number
  search: string
}

export const getBlogs = async ({ size, page, search }: IGetBlogsProps) => {
  const url = `${ENDPOINTS.BLOG}${search}&size=${size}&start=${page}&sort=published_at desc`

  const response = await fetch(url)

  const { hits } = await response.json()

  return hits
}
