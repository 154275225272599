import React from 'react'

export const MainError = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
      fill="#F56A50"
    />
    <path
      d="M41.333 22.6641L22.6663 41.3307"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M22.667 22.6641L41.3337 41.3307"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
