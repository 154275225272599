import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled(TextUI)``

export const Space = styled(GlobalStyles.DefaultSpace)``

export const ContentBlogList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid var(--gray100);
  }

  li:last-child {
    border: none;
  }
`
