import styled from 'styled-components'

import { SelectNative } from '@interco/inter-ui/components/SelectNative'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'
import { Icon } from '@/components/IconComponent'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Text = styled(TextUI)``

export const Select = styled(SelectNative)`
  option:first-child {
    display: none;
  }
`

export const Separator = styled(GlobalStyles.DefaultSeparator)`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`

export const IconLoading = styled(Icon).attrs({
  name: 'loading',
  width: 24,
  height: 24,
  color: 'var(--primary500)',
})``
