import { IWbHttpOptions, WbSessionActions } from '@interco/inter-webview-bridge'

enum CustomActions {
  GET_SUPER_APP_TYPE = 'getSuperAppType',
  SHARE_WEB_VIEW_SCREENSHOT = 'shareWebViewScreenshot',
  GET_SESSION_TYPE = 'getSessionType',
  DOWNLOAD_FILE = 'downloadFile',
  SHARE_FILE_ANDROID = 'shareFile',
  GET_ONBOARDING = 'getOnboarding',
  SET_ONBOARDING = 'setOnboarding',
  OPEN_NATIVE_BROWSER = 'openNativeBrowser',
}

export const Actions = {
  ...WbSessionActions,
  ...CustomActions,
}

type CommonArguments = {
  endpoint: string
  headers?: Record<string, string>
  options?: IWbHttpOptions
}

type GetOrDelete = CommonArguments & {
  method: 'GET' | 'DELETE'
}

export type PostOrPut = CommonArguments & {
  method: 'POST' | 'PUT'
  body?: Record<string, unknown>
}

export type Request = GetOrDelete | PostOrPut

export type SuperAppTypeOld = {
  superAppType: AppTypeOld
}
export type UserFeatures = {
  features: UserFeature[]
}

export type CheckFeature = {
  isEnabled: boolean
}

export type SessionType = {
  isBasicAccount: boolean
}

export type VerifyOnboarding = {
  onboardingWasPresented: boolean
}
