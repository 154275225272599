import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import * as Actions from './actions'

export const INITIAL_STATE: RootState = {
  user: undefined,
  onboarding: undefined,
  isAppPF: false,
  isAppPJ: false,
  mobilePlatform: undefined,
  appType: undefined,
  isPersonalAccount: true,
  accountType: 'PF',
  isBasicAccount: false,
  home: undefined,

  // TODO: remover after finish bridge integration
  debug: false,
}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(Actions.setProductData, (draft, action) => {
  const { products } = action.payload
  draft.products = products
})

addCase(Actions.setUser, (draft, action) => {
  const { user } = action.payload
  draft.user = user
})

addCase(Actions.setProductDetailExpanded, (draft, action) => {
  const { product } = action.payload
  draft.productDetailExpanded = product
})
addCase(Actions.setProductDetail, (draft, action) => {
  const { product } = action.payload
  draft.productDetail = product
})

addCase(Actions.setOnboarding, (draft, action) => {
  const { onboarding } = action.payload
  draft.onboarding = onboarding
})

addCase(Actions.setAppType, (draft, action) => {
  const { appType } = action.payload
  draft.appType = appType
  draft.isPersonalAccount = appType === 'PF'
  if (appType === 'PF') {
    draft.accountType = draft.user?.cpf.length === 11 ? 'PF' : 'MEI'
  } else {
    draft.accountType = 'PJ'
  }

  draft.isAppPF = appType === 'PF'
  draft.isAppPJ = appType !== 'PF'
})

addCase(Actions.setMobilePlatform, (draft, action) => {
  draft.mobilePlatform = action.payload
})

addCase(Actions.setBasicAccount, (draft, action) => {
  draft.isBasicAccount = action.payload
})

addCase(Actions.setHome, (draft, action) => {
  draft.home = action.payload
})

export default reducer
