import React from 'react'

import * as S from './styles'

export const Accordion = () => (
  <S.AccordionContainer>
    <S.Content height="40" />
    <S.Circle width="40" height="40" />
  </S.AccordionContainer>
)

const renderDefaultBlock = () => (
  <S.ContentBlog>
    <S.ContentBlogThumb />
    <S.ContentBlogInfo>
      <S.Content height="20" marginBottom={10} />
      <S.Content height="30" />
    </S.ContentBlogInfo>
  </S.ContentBlog>
)

export const Skeletons = () => (
  <S.Container role="main" data-testid="skeletons-container">
    <S.Content height="20" width="200" marginBottom={10} />
    <S.Content height="50" marginBottom={10} />
    <S.Content height="200" marginBottom={24} />

    <S.Content height="20" width="200" marginBottom={10} />
    <S.Content height="200" marginBottom={24} />

    <S.ContainerBlog>
      <S.ContentBlogHeader>
        <S.Content height="20" width="150" marginBottom={10} />
        <S.Content height="20" width="80" marginBottom={10} />
      </S.ContentBlogHeader>

      {[...Array(5)].map(() => renderDefaultBlock())}
    </S.ContainerBlog>
  </S.Container>
)
