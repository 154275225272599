import React from 'react'

import * as S from './styles'

type VideoPlayerProps = {
  src: string
}

export const VideoPlayer = ({ src }: VideoPlayerProps) => (
  <S.Container>
    <S.Player
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </S.Container>
)
