import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const Container = styled.li`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-bottom: 12px;
`

export const Image = styled.div<{ url: string }>`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  margin-right: 10px;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
`

export const ContentDescription = styled.div`
  display: flex;
  flex: 1%;
  flex-direction: column;

  span:first-child {
    margin-bottom: 4px;
  }
  span:last-child {
    margin-top: 4px;
  }
`

export const Link = styled.span`
  text-decoration: none;
`

export const Text = styled(TextUI)``

export const LimitText = styled(TextUI)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`
