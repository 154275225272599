import { formatToBRL } from '@interco/lib-util'

interface IStep {
  key: string
  value?: string | number
  copy?: boolean
}

const formatGlobalCurrency = (value: number) => {
  if (value === undefined) {
    return undefined
  }

  return formatToBRL(value)
}
export const step = (
  productKey: ProductKey,
  serviceKey: ServiceKey,
  service: Service,
  isMeiOrPj: boolean,
  choosedYear: number,
): Array<IStep> => {
  if (isMeiOrPj) {
    return []
  }
  if (productKey === 'assetsRights') {
    switch (serviceKey) {
      case 'checkingAccount':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          { key: 'Código/Banco', value: service.bankCode },
          { key: 'Agência', value: service.agency },
          { key: 'Conta/Dígito', value: service.account },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
        ]
      case 'savingsAccount':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          { key: 'Código/Banco', value: service.bankCode },
          { key: 'Agência', value: service.agency },
          { key: 'Conta/Dígito', value: service.account },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
        ]
      case 'fixedIncome':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
          { key: 'Nome da fonte pagadora', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'treasuryDirect':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
        ]
      case 'investmentFund':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
        ]
      case 'infrastructureFund':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Quantidade em dez/${choosedYear - 1}`,
            value: service?.balanceLastYear,
          },
          {
            key: `Quantidade em dez/${choosedYear}`,
            value: service?.balanceYear,
          },
        ]
      case 'realEstateFund':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Quantidade em dez/${choosedYear - 1}`,
            value: service?.balanceLastYear,
          },
          {
            key: `Quantidade em dez/${choosedYear}`,
            value: service?.balanceYear,
          },
        ]

      case 'consortium':
        return [
          { key: 'Localização', value: service.country },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service?.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service?.balanceYear as number),
          },
          { key: 'Nome da fonte pagadora', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]

      case 'variableIncomeAssets':
        // return [
        //   { key: 'Localização', value: service.country },
        //   { key: 'Ativo', value: service.assetName },
        //   { key: 'CNPJ', value: service.payingSourceFtin, copy: true },
        //   { key: 'Discriminação', value: service.description, copy: true },
        //   { key: `Quantidade em dez/${choosedYear-1}`, value: service.lastYearPosition },
        //   {
        //     key: `Quantidade em dez/${choosedYear}`,
        //     value: service.currentYearPosition,
        //   },
        //   { key: `Tipo`, value: service.saleType },
        // ]
        return []
      default:
        return []
    }
  }

  if (productKey === 'realDebtOnus') {
    switch (serviceKey) {
      case 'payrollLoan':
        return [
          { key: 'Localização', value: service.country },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service.debitBalanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service.debitBalanceYear as number),
          },
          {
            key: `Valor pago em ${choosedYear}`,
            value: formatGlobalCurrency(service.paidAmountCurrentYear as number),
          },
        ]
      case 'personalLoan':
        return [
          { key: 'Localização', value: service.country },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service.debitBalanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service.debitBalanceYear as number),
          },
          {
            key: `Valor pago em ${choosedYear}`,
            value: formatGlobalCurrency(service.paidAmountCurrentYear as number),
          },
        ]
      case 'realEstateLoan':
        return [
          { key: 'Localização', value: service.country },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service.balanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service.balanceYear as number),
          },
          {
            key: `Valor pago em ${choosedYear}`,
            value: formatGlobalCurrency(service.paidAmountCurrentYear as number),
          },
        ]
      default:
        return []
    }
  }

  if (productKey === 'debtsLinkedRuralActivities') {
    switch (serviceKey) {
      case 'ruralFinance':
        return [
          { key: 'Número do contrato', value: service.contractNumber },
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Nome da fonte pagadora', value: service.payingSourceName },
          { key: 'Discriminação', value: service.description },
          {
            key: `Situação em dez/${choosedYear - 1}`,
            value: formatGlobalCurrency(service.debitBalanceLastYear as number),
          },
          {
            key: `Situação em dez/${choosedYear}`,
            value: formatGlobalCurrency(service.debitBalanceYear as number),
          },
        ]
      default:
        return []
    }
  }

  if (productKey === 'incomeTaxFree') {
    switch (serviceKey) {
      case 'savings':
        return [
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Nome da fonte pagadora', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'fixedIncome':
        return [
          { key: 'CNPJ da fonte pagadora', value: service.payingSourceFtin, copy: true },
          { key: 'Nome da fonte pagadora', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'infrastructureFund':
        return [
          { key: 'CNPJ da fonte pagadora', value: '18.945.670/0001-46', copy: true },
          { key: 'Nome da fonte pagadora', value: 'Inter Distrib de Tit e Val Mob LTDA' },
          { key: 'CNPJ do fundo', value: service.payingSourceFtin, copy: true },
          { key: 'Nome do fundo', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'realEstateFund':
        return [
          { key: 'CNPJ da fonte pagadora', value: '18.945.670/0001-46', copy: true },
          { key: 'Nome da fonte pagadora', value: 'Inter Distrib de Tit e Val Mob LTDA' },
          { key: 'CNPJ do fundo imobiliário', value: service.payingSourceFtin, copy: true },
          { key: 'Nome do fundo imobiliário', value: service.payingSourceName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      default:
        return []
    }
  }

  if (productKey === 'incomeSubjectExclusiveTaxation') {
    switch (serviceKey) {
      case 'fixedIncome':
        return [
          { key: 'CNPJ da fonte pagadora', value: service.payingCompanyFtin, copy: true },
          { key: 'Nome da fonte pagadora', value: service.payingCompanyName },
          { key: 'Discriminação', value: service.description },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'treasuryDirect':
        return [
          { key: 'CNPJ da fonte pagadora', value: service.payingCompanyFtin, copy: true },
          { key: 'Nome da fonte pagadora', value: service.payingCompanyName },
          { key: 'Discriminação', value: service.description },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'investmentFund':
        return [
          { key: 'CNPJ da fonte pagadora', value: '18.945.670/0001-46', copy: true },
          { key: 'Nome da fonte pagadora', value: 'Inter Distrib de Tit e Val Mob LTDA' },
          { key: 'CNPJ do fundo de investimento', value: service.payingCompanyFtin, copy: true },
          { key: 'Nome do fundo de investimento', value: service.payingCompanyName },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      case 'realEstateFund':
        return [
          { key: 'CNPJ da fonte pagadora', value: '18.945.670/0001-46', copy: true },
          { key: 'Nome da fonte pagadora', value: 'Inter Distrib de Tit e Val Mob LTDA' },
          { key: 'CNPJ do fundo imobiliário', value: service.payingCompanyFtin, copy: true },
          { key: 'Nome do fundo imobiliário', value: service.payingCompanyName },
          { key: 'Discriminação', value: service.description },
          { key: 'Valor', value: formatGlobalCurrency(service?.value as number) },
        ]
      default:
        return []
    }
  }

  return []
}
