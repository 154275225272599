import React from 'react'

import { requestAnalytics } from '@/services/bridge'

import { MenuItem } from './menu-item'
import * as S from './styles'

interface IMenuListProps {
  title: string
  subtitle: string
  data: DeclarationFacilitated | undefined
  handleNavigation: (product: ProductDetail) => void
}

export { MenuItem } from './menu-item'

export const MenuList = ({ title, subtitle, data, handleNavigation }: IMenuListProps) => {
  const handleRequestAnalytics = (productKey: string) => {
    switch (productKey) {
      case 'assetsRights':
        requestAnalytics('AcessoProdInterBensDireitos')
        break

      case 'incomeTaxFree':
        requestAnalytics('AcessoProdInterIsentos')
        break

      case 'incomeSubjectExclusiveTaxation':
        requestAnalytics('AcessoProdInterTrib')
        break

      case 'realDebtOnus':
        requestAnalytics('AcessoProdInterDividasOnus')
        break

      // TODO: havera analytics para "debtsLinkedRuralActivities"?
      default:
        requestAnalytics('AcessoProdInterBensDireitos')
        break
    }
  }
  const otherInformationGroups =
    data &&
    data.newProducts?.groups.filter(
      (item) =>
        data.products.filter((product) => product.productKey === item.id).length === 0 &&
        item.otherInformation === true,
    )
  return (
    <S.Contaioner>
      <S.Text variant="headline-h3" semiBold colorWeight={500}>
        {title}
      </S.Text>

      <S.Space />

      <S.Text variant="body-3">{subtitle}</S.Text>

      <S.Space />
      {data &&
        data.products.map((product) => (
          <MenuItem
            title={product.metadata.title}
            onClick={() => {
              handleRequestAnalytics(product.productKey)
              handleNavigation({
                productKey: product.productKey,
                pageTitle: product.metadata.title,
                formDescription: product.metadata.description,
                services: product.services,
              })
            }}
          />
        ))}

      <S.Contaioner>
        {data &&
          data.newProducts?.groups
            .filter(
              (item) =>
                data.products.filter((product) => product.productKey === item.id).length === 0 &&
                item.otherInformation !== true,
            )
            .map((group) => (
              <MenuItem
                title={group.title}
                onClick={() => {
                  handleRequestAnalytics(group.id)
                  handleNavigation({
                    productKey: group.id,
                    pageTitle: group.title,
                    formDescription: group.description,
                    services: [],
                  })
                }}
              />
            ))}
      </S.Contaioner>
      {otherInformationGroups && otherInformationGroups.length > 0 && (
        <>
          <S.Space />
          <S.Space />
          <S.Space />

          <S.Text variant="body-3" bold colorWeight={500}>
            Outras informações
          </S.Text>

          <S.Space />

          {otherInformationGroups.map((group) => (
            <MenuItem
              title={group.title}
              onClick={() => {
                handleRequestAnalytics(group.id)
                handleNavigation({
                  productKey: group.id,
                  pageTitle: group.title,
                  formDescription: group.description,
                  services: [],
                })
              }}
            />
          ))}
        </>
      )}
    </S.Contaioner>
  )
}
