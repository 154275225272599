export default {
  title: 'Chegou a declaração facilitada',
  description:
    'Visualize facilmente as informações que você precisa preencher na sua declaração de IR.',
  contents: [
    {
      icon: 'Check',
      contentTitle: 'Faça você mesmo',
      contentDescription: 'Autonomia para que você mesmo consiga fazer sua declaração.',
    },
    {
      icon: 'Check',
      contentTitle: 'Informações personalizadas',
      contentDescription:
        'Somente informações que importam. Preencha de acordo com os produtos que você possui contrato no Inter.',
    },
    {
      icon: 'Check',
      contentTitle: 'Mesmas nomenclaturas',
      contentDescription:
        'Para uma melhor orientação utilizamos os mesmos termos presentes no formulário da Receita Federal.',
    },
  ],
  button: { text: 'Conferir o passo a passo', icon: 'chevron_right' },
}
