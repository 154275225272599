import React from 'react'

import { GuideSteps } from '@/components'

import * as S from './styles'

const steps = [
  {
    title: 'Acesse o site do Inter',
    description: (
      <S.Text variant="body-3" colorWeight={400}>
        No menu superior, clique em <strong>Acessar</strong> e depois em{' '}
        <strong>Acessar minha conta digital PF/MEI</strong>
      </S.Text>
    ),
  },
  {
    title: 'Entre na versão anterior do IB',
    description: (
      <S.Text variant="body-3" colorWeight={400}>
        Vá em <strong>Acessar versão anterior do Internet Banking</strong> e faça o seu login
      </S.Text>
    ),
  },
  {
    title: 'Pegue o seu demonstrativo',
    description: (
      <S.Text variant="body-3" colorWeight={400}>
        No menu lateral, vá em <strong>Serviços</strong> e selecione o ano base do seu Informe de
        Rendimentos
      </S.Text>
    ),
  },
]

export const ProductWithoutIr = () => (
  <S.Container>
    <S.Text variant="headline-h3" semiBold colorWeight={500}>
      Acesse o Internet Banking
    </S.Text>

    <S.Space height={16} />

    <S.Text variant="body-3" colorWeight={400}>
      O seu demonstrativo para este ano base selecionado está disponível apenas na versão antiga do
      Internet Banking
    </S.Text>
    <S.Space height={24} />

    <GuideSteps steps={steps} mgSmall />
  </S.Container>
)
