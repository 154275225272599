export const copyToClipboard = async (
  value: string,
  key: string,
  setCopied: (value: string) => void,
) => {
  setCopied(key)

  try {
    const input = document.createElement('input')
    input.setAttribute('value', value)
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  } catch (_) {
    //
  }

  setTimeout(() => {
    setCopied('')
  }, 1500)
}
