import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Header, Service } from '@/components'
import { RoutesNames } from '@/routes/routes'
import useGlobalState from '@/contexts/global-state'
import { SubGroupComponent } from '@/new-components/subGroup'

import * as S from './styles'

export const ProductDetail = () => {
  const [{ home, productDetail, products }] = useGlobalState()
  const history = useHistory()
  const [expanded, setExpanded] = useState('')

  const { pageTitle, formDescription, services } = productDetail || { services: [] }
  useEffect(() => {
    if (services.length === 1) {
      setExpanded(services[0].serviceName)
    } else if (
      services.length === 0 &&
      products?.products.filter((item) => item.groupId === productDetail?.productKey).length === 1
    ) {
      setExpanded(
        products?.products.filter((item) => item.groupId === productDetail?.productKey)[0].id,
      )
    }
  }, [productDetail?.productKey, products?.products, services])

  return (
    <S.Container>
      <Header
        onRightButtonClick={() =>
          history.push(home?.data?.accountType === 'PF' ? RoutesNames.HOME : RoutesNames.ONBOARDING)
        }
        rightIcon={home?.data?.accountType === 'PF' ? '' : 'help'}
        onBackClick={() => history.goBack()}
      >
        {pageTitle || ''}
      </Header>
      <S.Space height={24} />
      <S.Text variant="body-3">{formDescription || ''}</S.Text>
      <S.Space height={24} />
      <S.Sepator />
      <S.ContainerProduct>
        {services &&
          services
            .filter((service) => {
              if (service.productKey === 'assetsRights' && home?.data?.accountType === 'PF') {
                if (service.serviceKey === 'ruralLoan' || service.serviceKey === 'realEstateLoan') {
                  return false
                }
              }
              return true
            })
            .map((service) => (
              <Service
                expanded={expanded}
                setExpanded={setExpanded}
                key={service.serviceKey}
                productKey={service.productKey}
                serviceKey={service.serviceKey}
                serviceName={service.serviceName}
                serviceTitle={service.serviceTitle}
                metadata={service.metadata}
                data={service.data}
              />
            ))}
        {products?.products
          .filter((item) => item.groupId === productDetail?.productKey)
          .map((item) => (
            <SubGroupComponent
              {...item}
              idExpandedSection={expanded}
              setIdExpandedSection={setExpanded}
            />
          ))}
      </S.ContainerProduct>
    </S.Container>
  )
}
