export default {
  found: 1,
  start: 0,
  hit: [
    {
      id: '21ff027c-0063-4644-b2c8-fda566d953e1',
      fields: {
        title: 'Informe de Rendimentos: tudo sobre a Declaração IR',
        introduction: 'Vem com a gente no passo a passo. ',
        created_at: '2021-06-08T17:24:15.315Z',
        updated_at: '2021-06-08T17:24:45.25Z',
        grid_image_url:
          'https://static.bancointer.com.br/blog/grid-images/edf95e34-c933-4b48-bc16-811b0598f360.jpg',
        published_at: '2018-03-05T17:20:35Z',
        category_id: '13',
        slug: 'informe-de-rendimentos-entenda-tudo-para-fazer-declaracao-ir-2018',
        category_name: 'Cuidando do seu dinheiro ',
      },
    },
  ],
}
