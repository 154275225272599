import React, { ReactNode } from 'react'

import * as S from './styles'

export interface IAccordionProps {
  title: string
  id: string
  titleIcon?: ReactNode
  description?: string
  subtitle?: string
  children: ReactNode
  expanded: string
  setExpanded: (str: string) => void
  titleIconOnclick?: () => void
}

type CustomLabelProps = Omit<IAccordionProps, 'children' | 'expanded' | 'setExpanded' | 'id'> & {
  expanded?: boolean
}

const CustomLabel = ({ expanded, title, titleIcon, subtitle }: CustomLabelProps) => (
  <S.ContainerTitle>
    <div>
      <S.Title variant="body-3" bold colorWeight={500} style={{ paddingRight: '15px' }}>
        {title}
        {titleIcon}
      </S.Title>
      {subtitle && (
        <S.Subtitle data-testid="subtitle" variant="body-3">
          {subtitle}
        </S.Subtitle>
      )}
    </div>
    {expanded ? (
      <S.IconArrowUp data-testid="icon-arrow-up" />
    ) : (
      <S.IconArrowDown data-testid="icon-arrow-dow" />
    )}
  </S.ContainerTitle>
)

export const Accordion = ({
  title,
  id,
  titleIcon,
  subtitle,
  children,
  description,
  titleIconOnclick,
  expanded,
  setExpanded,
}: IAccordionProps) => (
  <div>
    <S.Accordion
      labelComponent={
        <CustomLabel
          expanded={expanded === id}
          title={title}
          titleIcon={titleIcon}
          subtitle={subtitle}
          titleIconOnclick={titleIconOnclick}
        />
      }
      expanded={expanded === id}
      onClick={() => setExpanded(expanded === id ? '' : id)}
    >
      <S.ContainerChildren>
        {description}
        {children}
      </S.ContainerChildren>
    </S.Accordion>
  </div>
)
