import styled from 'styled-components'

import { BottomSheet as BottomSheetUI } from '@interco/inter-ui/components/BottomSheet'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

import { Icon } from '../IconComponent'

export const BottomSheet = styled(BottomSheetUI)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0 0;
`
export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 0 0;
`

export const Text = styled(TextUI)``

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 16px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`

export const IconDownload = styled(Icon).attrs({
  width: 24,
  height: 24,
  name: 'download',
})``
export const IconEmail = styled(Icon).attrs({
  width: 24,
  height: 24,
  name: 'email',
})``
export const IconShare = styled(Icon).attrs({
  width: 24,
  height: 24,
  name: 'share',
})``
export const IconChevronRight = styled(Icon).attrs({
  width: 24,
  height: 24,
  name: 'chevron-right',
})``
