import React, { useState, MouseEvent, useMemo } from 'react'

import { ServiceName } from '@/common/constants/services'

import { Accordion, BottonSheetService, BottonSheetSimple } from '..'

import * as S from './styles'
import { RenderItems } from './components/RenderItems'

export interface ServiceExpandend extends ServiceItem {
  expanded: string
  setExpanded: (str: string) => void
}

export const Service = (props: ServiceExpandend) => {
  const { serviceName, metadata, expanded, serviceTitle, setExpanded } = props
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [bottomSheetQnty, setBottomSheetQnty] = useState<boolean>(false)

  const TitleIcon = useMemo(() => {
    if (metadata?.bottomSheetDescription) {
      return (
        <S.ContainerIconClick
          onClick={(event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            setIsVisible(true)
          }}
          data-testid="bottomSheet-button-icon-header-service"
        >
          <S.IconHelp />
        </S.ContainerIconClick>
      )
    }

    return null
  }, [metadata?.bottomSheetDescription])

  return (
    <S.Container>
      <Accordion
        expanded={expanded}
        id={serviceName}
        setExpanded={setExpanded}
        title={serviceTitle}
        titleIcon={TitleIcon}
      >
        <RenderItems props={props} setBottomSheetQnty={setBottomSheetQnty} />
      </Accordion>

      {isVisible && metadata?.bottomSheetDescription && (
        <BottonSheetService
          serviceName={ServiceName[serviceName as keyof typeof ServiceName]}
          instruction={metadata?.bottomSheetDescription}
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
        />
      )}

      {bottomSheetQnty && metadata?.importantInfo?.bottomSheet && (
        <BottonSheetSimple
          title={metadata?.importantInfo?.bottomSheet?.title}
          description={metadata?.importantInfo?.bottomSheet?.description}
          isVisible={bottomSheetQnty}
          onClose={() => setBottomSheetQnty(false)}
        />
      )}
    </S.Container>
  )
}
