import React from 'react'

import * as S from './styles'

interface IProductRetrotiveProps {
  retroative: Array<HomePfd>

  demonstrative: Demonstrative
  onFileDownload: (value: HomePfd) => void
  onIsVisibleSheetDemonstrative: (value: boolean) => void
}

export const ProductRetrotive = ({
  retroative,
  demonstrative,
  onFileDownload,
  onIsVisibleSheetDemonstrative,
}: IProductRetrotiveProps) => (
  <S.Container>
    <S.List>
      {retroative.map((item) => (
        <S.ListItem key={item.fileName}>
          <S.ContentFile>
            <S.IconReceipt />
            <S.Text variant="body-3" bold colorWeight={500}>
              {item.name}
            </S.Text>
          </S.ContentFile>
          <S.Space />
          <S.Text variant="caption-1" colorWeight={400}>
            {item.fileName}
          </S.Text>

          <S.Button
            variant="secondary"
            onClick={() => {
              onFileDownload(item)
              onIsVisibleSheetDemonstrative(true)
            }}
            icon={<S.Icon name="arrow-right" color="var(--primary500)" />}
          >
            {demonstrative?.buttons[0].name}
          </S.Button>
        </S.ListItem>
      ))}
    </S.List>
  </S.Container>
)
