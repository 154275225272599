import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

interface IHeaderProps extends DefaultHeader {
  ssn: string
  year?: string
  period?: string
  loadOnlyDemonstrative: boolean
}

export const getHome = async ({
  account,
  accountType,
  ssn,
  year = undefined,
  loadOnlyDemonstrative,
  period = undefined,
}: IHeaderProps) => {
  const url =
    loadOnlyDemonstrative === true ? '?loadOnlyDemonstrative=true' : '?loadOnlyDemonstrative=false'

  const response = await api<Home>(`${ENDPOINTS.HOME}${url}`, {
    method: 'GET',
    headers: {
      ssn,
      year: year!,
      'period-type': period!,
      'account-type': accountType,
      ...shouldInsertAccount({ accountType, account }),
    },
  })

  const resp: Home = {
    ...response,
    years: response?.years
      ? response?.years.sort((a, b) => {
          if (a.year > b.year) {
            return -1
          }
          return 1
        })
      : [],
  }
  return resp
}
