import styled from 'styled-components'

import * as GlobaStyles from '@/styles/styles'
import { Carousel as CarouselUI } from '@interco/inter-ui/components/Carousel'

export const Container = styled.div`
  .slick-dots {
    margin-bottom: 15px;
  }
`

export const Carousel = styled(CarouselUI)``

export const ContentImage = styled.div``

interface IImageProps {
  url: string
}

export const Link = styled.div`
  text-decoration: none;
`

export const Image = styled.img.attrs<IImageProps>(({ url }) => ({
  src: url,
  alt: 'img',
}))<IImageProps>`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

export const Space = styled(GlobaStyles.DefaultSpace)``
