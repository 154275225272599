export default {
  metadata: {
    headerTitle: 'Passo a passo para declarar',
    title: 'Faça a sua declaração do IR',
    description:
      'Aqui, você encontra o passo a passo de como declarar os seus produtos Inter na ReceitaFederal.',
    subtitle: 'Seus produtos no Inter',
    complement:
      'Confira o que você precisa preencher na sua declaração de acordo com cada produto quepossui aqui no Inter.',
    important: {
      icon: 'Info',
      title: 'Complete sua declaração com o PDF',
      description:
        'Algumas informações do seu IR estão apenas no arquivo em PDF. É importante que você outilize para completar sua declaração. [Faça o download do PDF]().',
    },
    partnerProducts: {
      title: 'Produtos de parceiros Inter',
      description:
        'Por aqui, você confere como obter o seu informe e fazer a sua declaração dos produtoscontratados pelos nossos parceiros.',
      options: ['PENSION'],
    },
  },
  assetsRights: {
    metadata: {
      title: 'Bens e direitos',
      description:
        'Nesta ficha, você precisará declarar tudo o que compõe o seu o patrimônio comocontribuinte e de seus dependentes.',
    },
    checkingAccount: {
      metadata: {
        title: 'Conta Corrente',
        type: 'CHECKING_ACCOUNT',
        productCodes: [
          {
            code: '1',
            description: ' - Depósito em conta corrente ou conta pagamento',
          },
        ],
        group: {
          code: '6',
          description: ' - Depósito à Vista e Numerário',
        },
      },
      data: [
        {
          country: 'Brasil',
          payingSourceFtin: '00.416.968/0001-01',
          bankCode: '077',
          description: 'Saldo em CC',
          agency: '0001',
          account: '11842779-2',
          balanceLastYear: 716.51,
          balanceYear: 2330.05,
        },
      ],
    },
    variableIncomeAssets: {
      metadata: {
        title: 'Renda Variável',
        type: 'VARIABLE_INCOME',
        doubtsHelper: {
          description:
            'Durante a declaração, você precisará informar o custo médio de cada ação adquirida. <br/><br/> Para te ajudar, preparamos um guia completo de como funciona a declaração de ações no Imposto deRenda',
          buttonTitle: 'Saiba como declarar suas ações',
          buttonEnabled: true,
        },
        productCodes: [],
      },
    },
  },
  realDebtOnus: {
    metadata: {
      title: 'Dívidas e ônus reais',
      description:
        'Nesta ficha são declaradas dívidas, tais quais empréstimos imobiliários, consignados,dentre outros.',
    },
    payrollLoan: {
      metadata: {
        title: 'Empréstimo consignado/pessoal',
        type: 'PAYROLL_LOAN',
        bottomSheetDescription:
          'Aqui você informa os pagamentos referentes ao empréstimo durante sua liquidação',
        productCodes: [
          {
            code: '12',
            description: ' - Sociedade de crédito, financiamento e investimento ',
          },
        ],
      },
      data: [
        {
          country: 'Brasil',
          description: 'Emprestimo Consignado',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Empréstimo com garantia de imovel',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Capital de Giro',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Resgate Express B3',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Antecipação de FGTS',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Antecipação de IR',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
        {
          country: 'Brasil',
          description: 'Renegociação de cartão',
          debitBalanceLastYear: 716.51,
          debitBalanceYear: 2330.05,
          paidAmountCurrentYear: 1234.0,
        },
      ],
    },
  },
  incomeTaxFree: {
    metadata: {
      title: 'Rendimentos isentos e não tributáveis',
      description:
        'Nesta ficha você deve declarar os ganhos obtidos durante o ano sobre os quais não incidetributação.',
    },
    fixedIncome: {
      metadata: {
        title: 'Renda Fixa',
        type: 'FIXED_INCOME',
        bottomSheetDescription:
          'Aqui estão os valores de rendimentos referentes aos papéis de LCI, LCA, CRI,CRA.',
        productCodes: [
          {
            code: '12',
            description:
              ' - Rendimentos de cadernetas de poupança, letras hipotecárias, letras de crédito do agronegócio e imobiliário (LCA e LCI) e certificados de recebíveis do agronegócio e imobiliários (CRA eCRI)',
          },
        ],
      },
      data: [
        {
          payingSourceFtin: '00.416.968/0001-01',
          payingSourceName: 'BANCO INTER S.A.',
          value: 0.0,
        },
      ],
    },
  },
  data: {
    groups: [
      {
        id: 'assetsRights',
        title: 'Bens e direitos',
        description:
          'Nesta ficha, você precisará declarar tudo o que compõe o seu o patrimônio comocontribuinte e de seus dependentes.',
        otherInformation: false,
      },
      {
        id: 'OUTRAS_INFORMACOES',
        title: 'Outras Informações',
        description: 'Outras Informações',
        otherInformation: true,
      },
    ],
    products: [
      {
        groupId: 'assetsRights',
        title: 'Global Account',
        id: 'CONTA_GLOBAL',
        productDetails: [
          {
            itemsWithBackgroundColor: [
              {
                text: '<b>Grupo 06 </b>- Depósito à vista e numerário',
                backgroundColor: '--gray100',
              },
              {
                text: '<b>Código 01 </b>- Depósito em conta corrente ou conta pagamento',
                backgroundColor: '--white100',
              },
            ],
            items: [
              {
                label: 'Localização',
                description: 'Localização: 249 - Estados Unidos',
              },
              {
                label: 'CNPJ',
                description: '00.416.968/0001-01',
                action: {
                  id: 'COPY_PAST',
                  textToCopyToClipboard: '00.416.968/0001-01',
                  icon: {
                    id: 'copy',
                    color: '--primary500',
                    backgroundColor: '--white100',
                  },
                },
              },
              {
                label: 'Situação em dez/2022 (USD)',
                description: 'US$ 0.00',
              },
              {
                label: 'Situação em dez/2022',
                description: 'R$ 0,00',
              },
              {
                label: 'Situação em dez/2023 (USD)',
                description: 'US$ 0.00',
              },
              {
                label: 'Situação em dez/2023',
                description: 'R$ 0,00',
              },
              {
                label: 'Taxa câmbio em dez/2022',
                description: 'R$ 5,58',
              },
              {
                label: 'Taxa câmbio em dez/2023',
                description: 'R$ 5,22',
              },
            ],
            textWithButton: [],
          },
        ],
      },
      {
        groupId: 'OUTRAS_INFORMACOES',
        title: 'Financiamento de Veículo',
        id: 'FINANCIAMENTO_VEICULO',
        productDetails: [
          {
            itemsWithBackgroundColor: [],
            items: [
              {
                label: 'Parcelas do contrato',
                description: '48',
              },
              {
                label: 'Parcelas pagas em 2023',
                description: '10',
              },
              {
                label: 'Valor pago em 2023',
                description: 'R$ 80,00',
              },
              {
                label: 'Saldo devedor em dez/2023',
                description: 'R$ 5.000,00',
              },
            ],
            textWithButton: [],
          },
        ],
      },
      {
        groupId: 'OUTRAS_INFORMACOES',
        title: 'Financiamento Imobiliário',
        id: 'FINANCIAMENTO_IMOBILIARIO',
        productDetails: [
          {
            itemsWithBackgroundColor: [],
            items: [
              {
                label: 'Situação em dez/2022',
                description: 'R$ 73.274,00',
              },
              {
                label: 'Situação em dez/2023',
                description: 'R$ 58.393,00',
              },
              {
                label: 'Valor pago em 2023',
                description: 'R$ 14.881,00',
              },
            ],
            textWithButton: [],
          },
        ],
      },
      {
        groupId: 'assetsRights',
        title: 'Criptomoedas',
        id: 'CRIPTO_COINS',
        productDetails: [
          {
            itemsWithBackgroundColor: [],
            items: [],
            textWithButton: [
              {
                description:
                  'Se você possui esse tipo de ativo em custódia ou tenha realizado venda superior a R$5 mil em criptomoedas no ano-exercício, deverá realizar a declaração dos ativos na ficha “Bens e Direitos“. Além disso, as vendas mensais superiores a R$35 mil estão sujeitas a retenção de imposto sobre o ganho de capital. <br/><br/> A Receita Federal divide a classificação dos criptoativos em cinco códigos diferentes: 1, 2, 3, 10 e 99. Cada um deles se refere a um tipo diferente de bem digital. No entanto, é importante frisar que o Inter não possui autorização para orientar em detalhes quanto à declaração ou recolhimento de impostos. <br/><br/> Caso possua alguma dúvida, busque a orientação de um profissional de sua confiança.',
                button: {},
              },
            ],
          },
        ],
      },
    ],
  },
}
