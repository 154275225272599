import React from 'react'

import { useHistory } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { Alert } from '@interco/inter-ui/components/Alert'

import { ItemWithAction } from '../item-with-action'
import { ItemWithBackground } from '../ItemWithBackgroundColor'
import * as S from './styles'
import { ButtonToNewPageDetail } from '../ButtonToNewPageDetail'
import Table from '../table'
import { ScrollingCarousel } from '../scrolling-carousel'
import { TextWithButton } from '../text-with-button'

export interface ProductComponentProps extends SubGroupDetail {
  openBottomSheet: (data: BottomSheet) => void
}

export const ProductComponet = ({
  itemsWithBackgroundColor,
  items,
  pageDetails,
  textWithButton,
  openBottomSheet,
  table,
  scrollingCarousel,
  alert,
}: ProductComponentProps) => {
  const history = useHistory()

  console.log(textWithButton)
  return (
    <S.Container>
      <S.ColumnFlex gap="28px">
        {itemsWithBackgroundColor && itemsWithBackgroundColor.length > 0 && (
          <S.ColumnFlex gap="8px">
            {itemsWithBackgroundColor.map((item) => (
              <ItemWithBackground key={item.backgroundColor + item.text} {...item} />
            ))}
          </S.ColumnFlex>
        )}
        {items && items.length > 0 && (
          <S.ColumnFlex gap="16px">
            {items?.map((item) => (
              <ItemWithAction
                key={item.label + item.description}
                {...item}
                openBottomSheet={openBottomSheet}
              />
            ))}
          </S.ColumnFlex>
        )}
        {textWithButton &&
          textWithButton.length > 0 &&
          textWithButton?.map((item) => <TextWithButton {...item} />)}
        {pageDetails && (
          <ButtonToNewPageDetail
            {...pageDetails}
            handleNavigation={() =>
              history.push({
                pathname: RoutesNames.GENERIC_DETAIL_PAGE,
                state: pageDetails,
              })
            }
          />
        )}
        {table && <Table {...table} />}
        {scrollingCarousel && (
          <ScrollingCarousel {...scrollingCarousel} openBottomSheet={openBottomSheet} />
        )}
        {alert && (
          <Alert title={alert.title} type={alert.type}>
            {alert.description}
          </Alert>
        )}
      </S.ColumnFlex>
    </S.Container>
  )
}
