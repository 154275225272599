import React, { useCallback, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isMobile } from '@interco/lib-util'
import { RoutesNames } from '@/routes/routes'
import { changeTheme, Theme } from '@interco/inter-ui'
import { BridgeService } from '@/services'
import { useGlobalState } from '@/contexts/global-state'
import * as Actions from '@/contexts/global-state/reducer/actions'
import { Skeletons } from '@/components'
import { WbSessionActions } from '@interco/inter-webview-bridge'

const isAndroid = (): boolean => /Android/i.test(navigator.userAgent)

const Main = () => {
  const history = useHistory()
  const [{ user, appType }, dispatch] = useGlobalState()

  const getUserInfo = useCallback(async () => {
    if (user) return

    const response = await BridgeService.getUserInfo()
    dispatch(
      Actions.setUser({
        user: {
          ...response,
          cpf: response.cpf,
        },
      }),
    )
  }, [dispatch, user])

  const getAppType = useCallback(async () => {
    if (appType) return
    const superAppType = await BridgeService.getSuperAppType()

    dispatch(
      Actions.setAppType({
        appType: superAppType || 'PF',
      }),
    )
    if (superAppType === 'PF') {
      changeTheme(Theme.PF)
    }
    if (superAppType === 'PJ') {
      changeTheme(Theme.PJ)
    }
  }, [appType, dispatch])

  const getPlatform = useCallback(async () => {
    if (!isMobile()) return

    try {
      if (isAndroid()) {
        dispatch(Actions.setMobilePlatform('Android'))
      } else {
        dispatch(Actions.setMobilePlatform('iPhone'))
      }
    } catch (_) {
      dispatch(Actions.setMobilePlatform(undefined))
    }
  }, [dispatch])

  const getSessionType = useCallback(async () => {
    const { isBasicAccount } = await BridgeService.getSessionType()

    dispatch(Actions.setBasicAccount(isBasicAccount))
  }, [dispatch])

  const redirectFirstPage = useCallback(async () => {
    const { onboardingWasPresented } = await BridgeService.verifyOnboarding()
    if (appType !== undefined && user !== undefined) {
      if (onboardingWasPresented) {
        history.push(RoutesNames.HOME)
      } else {
        history.push(RoutesNames.ONBOARDING_FIRST_PAGE)
      }
    }
  }, [appType, history, user])

  const onHeaderBackPress = useCallback(() => {
    if (
      [RoutesNames.ROOT, RoutesNames.HOME, RoutesNames.ONBOARDING_FIRST_PAGE].includes(
        history.location.pathname,
      )
    ) {
      BridgeService.interWbNavigate.requestGoBack()
      return
    }
    history.goBack()
  }, [history])

  const onMount = useCallback(() => {
    const callStack = async () => {
      await getUserInfo()
      await getAppType()
      await getPlatform()
      await getSessionType()
      await redirectFirstPage()
      // await getHome()
    }
    // Timeout used because MSW not initialized
    BridgeService.inApp ? callStack() : setTimeout(callStack, 1_000)
  }, [getAppType, getPlatform, getSessionType, getUserInfo, redirectFirstPage])

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    BridgeService.iWb.addWebviewEventListener(WbSessionActions.GO_BACK, onHeaderBackPress)
  }, [onHeaderBackPress])

  return <Skeletons />
}

export default Main
