import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { Icon as IconUI } from '@/components/IconComponent'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--gray100);
  border-radius: 8px;
  padding: 16px;

  a {
    color: var(--primary500);
    font-weight: bold;
    text-decoration: none;
  }
`

export const Icon = styled(IconUI).attrs({
  name: 'info',
  width: 24,
  height: 24,
  color: 'var(--gray100)',
})``

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 12px;
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const TitleMarkwdown = styled(ReactMarkdown)`
  font-size: 12px;
  font-weight: bold;
`

export const DescriptionMarkwdown = styled(ReactMarkdown)`
  font-size: 12px;
`

export const Button = styled.button`
  color: var(--primary500);
  font-weight: bold;
  outline: none;
  border: none;
  background: none;
  font-size: 12px;
  text-align: left;
`
