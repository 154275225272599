import React from 'react'

import { useHistory } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'

import * as S from './styles'

interface IProductProps {
  choiceYear: string
  demonstrative: Demonstrative
  onFileDownload: (value: HomePfd) => void
  onIsVisibleSheetDemonstrative: (value: boolean) => void
}

export const Product = ({
  choiceYear,
  demonstrative,
  onFileDownload,
  onIsVisibleSheetDemonstrative,
}: IProductProps) => {
  const history = useHistory()

  const handleNavigate = async () => {
    history.push(RoutesNames.DECLARATON_FACILITATED)
  }

  return (
    <S.Container>
      {demonstrative?.alreadyAvailable ? (
        <S.DemonstrativeInfoEnabled>{demonstrative?.since}</S.DemonstrativeInfoEnabled>
      ) : (
        <S.DemonstrativeInfoDisabled>{demonstrative?.since}</S.DemonstrativeInfoDisabled>
      )}

      <S.ContentFile>
        <S.IconReceipt />
        <S.Text variant="body-3" bold colorWeight={500}>
          {demonstrative?.title} {choiceYear}
        </S.Text>
      </S.ContentFile>

      <S.Space height={24} />

      <S.Text bold>{demonstrative?.subtitle}:</S.Text>

      {demonstrative?.products?.length > 0 && (
        <S.List>
          {demonstrative?.products.map((item) => (
            <S.ListItem key={item}>
              <S.Text>{item}</S.Text>
            </S.ListItem>
          ))}
        </S.List>
      )}

      {demonstrative.alreadyAvailable && (
        <>
          <S.Button
            variant="primary"
            icon={<S.Icon name="arrow-right" color="white" />}
            onClick={() => {
              onFileDownload({
                name: demonstrative.downloadLink?.name || '',
                fileName: demonstrative.downloadLink?.fileName || '',
                url: demonstrative.downloadLink?.url || '',
              })
              onIsVisibleSheetDemonstrative(true)
            }}
          >
            {demonstrative?.buttons[0].name}
          </S.Button>

          <S.Button
            variant="secondary"
            onClick={handleNavigate}
            icon={<S.Icon name="arrow-right" color="var(--primary500)" />}
          >
            {demonstrative?.buttons[1].name}
          </S.Button>
        </>
      )}
    </S.Container>
  )
}
