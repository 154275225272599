import React from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { useHistory, useLocation } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'

import * as S from './styles'

export interface IStepButtonsProps {
  metadata: DoubtHelper
}
export const TextWithButton = ({ description, button }: TextWithButton) => {
  const history = useHistory()
  const location = useLocation()

  return (
    <S.ContainerBox data-testid="step-button-container">
      <S.Text variant="body-3">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description}</ReactMarkdown>
      </S.Text>

      {button && button.title && (
        <>
          <S.Space height={24} />
          <S.Button
            variant="primary"
            color="primary"
            onClick={() =>
              history.push({
                pathname: RoutesNames.VARIABLE_INCOME,
                state: location.state,
              })
            }
          >
            {button.title}
          </S.Button>
        </>
      )}
    </S.ContainerBox>
  )
}
