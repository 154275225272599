import styled from 'styled-components'

export const Container = styled.div``

export const RowItemSeparator = styled.div`
  border-bottom: 1px dashed var(--gray200);
  width: 100%;
`

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ContainerIconClick = styled.div`
  margin-left: 8px;
`
