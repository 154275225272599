import React from 'react'

import * as S from '../../styles'
import { ServiceExpandend } from '../..'
import { BoxInfo } from '../../..'
import { RowItem } from '../RowItem'
import { StepButton } from '../StepButton'

export interface RenderItemsProps {
  props: ServiceExpandend
  setBottomSheetQnty: (show: boolean) => void
}
export const keyText = 'Saiba mais'
export const RenderItems = ({ props, setBottomSheetQnty }: RenderItemsProps) => {
  const { productKey, serviceKey, metadata, data, serviceTitle } = props

  return (
    <>
      {metadata && metadata?.importantInfo && (
        <div data-testid="box-info-container">
          <S.Space height={12} />
          <BoxInfo
            title={`${metadata?.importantInfo.title}`}
            description={metadata?.importantInfo.description.replace(keyText, '')}
            action={
              metadata?.importantInfo.bottomSheet
                ? {
                    title: keyText,
                    handleAction: setBottomSheetQnty,
                  }
                : undefined
            }
          />
          <S.Space height={24} />
        </div>
      )}
      <S.Row>
        <RowItem
          {...props}
          productKey={productKey}
          serviceKey={serviceKey}
          serviceTitle={serviceTitle}
          data={data}
          metadata={metadata}
        />
      </S.Row>

      {metadata &&
        metadata.doubtsHelper &&
        productKey === 'assetsRights' &&
        serviceKey === 'variableIncomeAssets' && <StepButton metadata={metadata.doubtsHelper} />}
    </>
  )
}
