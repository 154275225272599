import React, { useState, ChangeEvent, useEffect, useMemo } from 'react'

import { useQuery } from 'react-query'

import * as Actions from '@/contexts/global-state/reducer/actions'
import { Header, Babi, MakeYourself, Skeletons, ShowError } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { ApiService, BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'
import { setHome } from '@/contexts/global-state/reducer/actions'
import Loading from '@/components/loading'

import { Demonstrative, HomeBlog } from './components'
import * as S from './styles'

interface IYearList {
  value: string
  text: string
}

export const Home = () => {
  const [yearSelected, setYearSelected] = useState<string>()
  const [periodSelected, setPeriodSelected] = useState<string>()
  const [{ user, accountType, home }, dispatch] = useGlobalState()
  const [hasError, setHasError] = useState({
    general: false,
    MEI: false,
  })
  const { isFetching, refetch } = useQuery<Home | undefined>(
    'getHome',
    async () =>
      ApiService.Home.getHome({
        ssn: user?.cpf || '',
        year: home === undefined ? undefined : yearSelected,
        period: periodSelected || undefined,
        account: user?.account as string,
        accountType,
        loadOnlyDemonstrative: home !== undefined,
      }),
    {
      enabled: home === undefined,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (val) => {
        if (home === undefined) {
          if (val) {
            return dispatch(Actions.setHome(val))
          }
        }
        if (val?.demonstrative?.retroative && val.demonstrative.retroative?.length === 0) {
          return dispatch(
            Actions.setHome({
              ...home,
              demonstrative: undefined,
              yearWithoutIr: val.yearWithoutIr,
              data: val.data,
            }),
          )
        }
        return dispatch(
          Actions.setHome({
            ...home,
            demonstrative: val?.demonstrative || undefined,
            yearWithoutIr: val?.yearWithoutIr,
            data: val?.data,
          }),
        )
      },

      onError: (error) => {
        setHasError({
          general: true,
          MEI: false,
        })
        return error
      },
    },
  )

  const handleSelectYear = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    dispatch(setHome({ ...home, choiceYear: value }))
    setYearSelected(value)

    requestAnalytics('InputAno', {
      content_name: value,
    })
  }

  const handleSelectPeriod = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target

    // dispatch(setHome({ ...home, choiceYear: value }))
    setPeriodSelected(value)

    requestAnalytics('InputPeriod', {
      content_name: value,
    })
  }

  const onSelectList = (years: Array<string>) => {
    const list: Array<IYearList> = []

    years.forEach((item) => {
      list.push({
        value: item,
        text: item,
      })
    })

    return list
  }

  useEffect(() => {
    if (!yearSelected && !periodSelected) return

    refetch()
  }, [refetch, yearSelected, periodSelected])

  useEffect(() => {
    if (home && home.choiceYear) {
      setYearSelected(home.choiceYear)
    }
    if (
      home &&
      home.metadata?.basePeriods &&
      home.metadata.basePeriods.length > 0 &&
      !periodSelected
    ) {
      setPeriodSelected(home.data?.choiceBasePeriod)
    }
  }, [home, periodSelected])

  const listYears = useMemo(() => {
    const format = home?.years?.map((val) => val.year)

    return format || []
  }, [home?.years])

  const listTrimester = useMemo(() => {
    const format = home?.metadata?.basePeriods?.map((val) => ({
      value: val.id,
      text: val.description,
    }))

    return format || []
  }, [home?.metadata?.basePeriods])

  if (isFetching && home === undefined) {
    return <Skeletons />
  }

  if (hasError.general) return <ShowError />

  return (
    <S.Container data-testid="home-page">
      <Header onBackClick={() => BridgeService.interWbNavigate.requestGoBack()}>
        Informe de rendimentos
      </Header>

      <S.Space height={24} />
      <S.Text variant="headline-h2" colorWeight={500} semiBold>
        {home?.title}
      </S.Text>

      <S.Space />

      <S.Select
        options={onSelectList(listYears)}
        value={yearSelected}
        label={home?.titleYear}
        onChange={handleSelectYear}
        data-testid="year-select"
      />

      {home?.data?.accountType !== 'PF' && (
        <S.Select
          options={listTrimester}
          value={periodSelected}
          label="Escolha o período base para cálculo"
          onChange={handleSelectPeriod}
          data-testid="period-select"
        />
      )}

      {isFetching && (
        <S.ContainerLoading data-testid="loadding-container">
          <Loading size={24} />
        </S.ContainerLoading>
      )}

      {!isFetching && (
        <Demonstrative
          choiceYear={home?.choiceYear}
          yearWithoutIr={home?.yearWithoutIr}
          demonstrative={home?.demonstrative as Demonstrative}
          data={home?.data}
        />
      )}

      {home && home.explicativeContents && home?.explicativeContents?.length > 0 && (
        <>
          <S.Separator />
          <MakeYourself
            data-testid="home-carousel"
            explicativeContents={home?.explicativeContents}
            // accountType={home?.data?.accountType}
          />
        </>
      )}

      {home && home.searchBlog && (
        <>
          <S.Space height={50} />
          <HomeBlog search={home.searchBlog.join('%20')} />
        </>
      )}

      <S.Separator />
      {accountType !== 'PJ' && <Babi />}
    </S.Container>
  )
}
