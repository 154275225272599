import React, { useState } from 'react'

import useGlobalState from '@/contexts/global-state'
import { copyToClipboard } from '@/utils/string/copyPast'

import * as S from '../../styles'
import { step } from '../../utils/step'

export const RowItem = (props: ServiceItem) => {
  const { productKey, serviceKey, data, metadata } = props

  const [{ home }] = useGlobalState()
  const [copied, setCopied] = useState<string>('')

  return (
    <div data-testid="row-item-container">
      <S.Space height={5} />
      {metadata?.group && (
        <S.Group variant="caption-1" data-testid="group-item">
          <S.Text variant="body-3" bold colorWeight={500}>
            Grupo {metadata.group.code}
          </S.Text>
          {metadata.group.description}
        </S.Group>
      )}
      {metadata && metadata?.productCodes?.length > 0 && (
        <div data-testid="cod-item">
          {metadata.productCodes.map((item) => (
            <S.BoxGroup isGroup={!!metadata.group?.code}>
              <S.Text variant="caption-1">
                <S.Text variant="body-3" bold colorWeight={500}>
                  Código {item.code}
                </S.Text>
                {item.description}
              </S.Text>
            </S.BoxGroup>
          ))}
          <S.Space height={10} />
        </div>
      )}
      {data &&
        data.length > 0 &&
        data.map((item, idx) => {
          const stepData = step(
            productKey,
            serviceKey,
            item,
            home?.data?.accountType !== 'PF',
            parseInt(`${home?.choiceYear}`, 10),
          )
          if (stepData.length === 0) return null

          return (
            <div data-testid="rowItem-item">
              {item?.productCode && item?.productCode?.code && (
                <S.BoxGroup isGroup={!!metadata?.group?.code}>
                  <S.Text variant="caption-1">
                    <S.Text variant="body-3" bold colorWeight={500}>
                      Código {item?.productCode?.code}
                    </S.Text>
                    {item?.productCode?.description}
                  </S.Text>
                  <S.Space height={10} />
                </S.BoxGroup>
              )}

              {stepData.map((s) => (
                <S.RowItem oneItem={s.value === undefined || s.value === null || s.value === ''}>
                  <S.SectionMaxWidth>
                    <S.TextLine variant="caption-1" colorWeight={500}>
                      {s.key}
                    </S.TextLine>
                  </S.SectionMaxWidth>
                  <S.SectionMaxWidth justifyContent="flex-end">
                    {s.value && (
                      <S.ContainerValue>
                        <S.LabelTextAlignEnd variant="caption-1" bold colorWeight={500}>
                          {s.value}
                        </S.LabelTextAlignEnd>

                        {s.copy && s.value !== undefined && (
                          <S.ButtonCopy
                            data-testid="copy-button"
                            onClick={() =>
                              copyToClipboard(s.value as string, `${s.value}-${idx}`, setCopied)
                            }
                            type="button"
                          >
                            <S.IconCopy />

                            <S.Tooltip active={copied === `${s.value}-${idx}`}>
                              <span>copiado</span>
                            </S.Tooltip>
                          </S.ButtonCopy>
                        )}
                      </S.ContainerValue>
                    )}
                  </S.SectionMaxWidth>
                </S.RowItem>
              ))}

              {idx + 1 !== data.length && <S.RowItemSeparator />}
            </div>
          )
        })}
    </div>
  )
}
