import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled(GlobalStyles.DefaultContainer)`
  > svg {
    height: 320px;
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const ContainerLoading = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Sepator = styled(GlobalStyles.DefaultSeparator)``

export const Text = styled(TextUI)``
