import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

interface IHeaderProps extends DefaultHeader {
  ssn: string
  year: string
}

export const sendEmail = async ({ account, accountType, ssn, year }: IHeaderProps) =>
  api<string>(ENDPOINTS.SEND_EMAIL, {
    method: 'POST',
    body: {
      ssn,
      year,
    },
    headers: {
      'account-type': accountType,
      ...shouldInsertAccount({ accountType, account }),
    },
  })
