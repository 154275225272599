import Main from '@/main'
import * as Pages from '@/pages'

export const RoutesNames = {
  ROOT: '/',
  HOME: '/home',
  BLOG: '/blog',
  ONBOARDING: '/onboarding',
  ONBOARDING_FIRST_PAGE: '/onboarding-first-page',
  PRODUCT_DETAIL: '/product-detail',
  DECLARATON_FACILITATED: '/declaration-facilitated',
  VARIABLE_INCOME: '/variable-income',
  CONSORTIUM: '/consortium',
  PENSION: '/pension',
  GENERIC_DETAIL_PAGE: '/detail',
}

export default [
  { path: RoutesNames.ROOT, exact: true, component: Main },
  { path: RoutesNames.HOME, exact: true, component: Pages.Home },
  { path: RoutesNames.BLOG, exact: true, component: Pages.Blogs },
  { path: RoutesNames.PENSION, exact: true, component: Pages.Pension },
  { path: RoutesNames.CONSORTIUM, exact: true, component: Pages.Consortium },
  { path: RoutesNames.ONBOARDING, exact: true, component: Pages.OnBoarding },
  { path: RoutesNames.ONBOARDING_FIRST_PAGE, exact: true, component: Pages.OnBoarding },
  { path: RoutesNames.PRODUCT_DETAIL, exact: true, component: Pages.ProductDetail },
  { path: RoutesNames.VARIABLE_INCOME, exact: true, component: Pages.VariableIncome },
  {
    path: RoutesNames.DECLARATON_FACILITATED,
    exact: true,
    component: Pages.DeclarationFacilitated,
  },
  { path: RoutesNames.VARIABLE_INCOME, exact: true, component: Pages.VariableIncome },
  { path: RoutesNames.CONSORTIUM, exact: true, component: Pages.Consortium },
  { path: RoutesNames.PENSION, exact: true, component: Pages.Pension },
  { path: RoutesNames.GENERIC_DETAIL_PAGE, exact: true, component: Pages.GenericDetailPage },
]
