import React from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { Alert } from '@interco/inter-ui/components/Alert'

import * as S from './styles'

type PensionCardImportantProps = {
  info?: {
    icon?: string
    title?: string
    subtitle?: string
    description?: string
  }
}

export const PensionCardImportant = ({ info }: PensionCardImportantProps) => {
  if (!info) {
    return <></>
  }

  return (
    <S.Container>
      <Alert title={info?.title || ''} type="grayscale">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {info?.subtitle || info?.description || ''}
        </ReactMarkdown>
      </Alert>
    </S.Container>
  )
}
