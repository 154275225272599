import styled from 'styled-components'

import { Icon as IconUI } from '@/components/IconComponent'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

const marginHorizontalDefault = GlobalStyles.marginHorizontalDefault * 2

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Sepator = styled(GlobalStyles.DefaultSeparator)``

export const Text = styled(TextUI)``

export const ContainerProduct = styled.div`
  > div {
    border-bottom: 1px solid var(--gray200);
  }
  > div:last-child {
    border-bottom: 0;
  }
`
export const ContainerLoading = styled(GlobalStyles.DefaultContainer)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Icon = styled(IconUI)``

export const ContainerVideo = styled.div`
  margin-left: -24px;
  width: calc(100% + ${marginHorizontalDefault}px);
`
