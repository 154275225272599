import React, { useState } from 'react'

import { Accordion } from '@/components'

import * as S from './styles'
import { PensionCardSteps } from '../pension-card-steps'
import { PensionCardImportant } from '../pension-card-important'

interface StepByStepPensionProps {
  options?: PensionStepsOptions[]
  stepBySteps?: PensionStepByStep[]
}

export const StepByStepPension = (props: StepByStepPensionProps) => {
  const { options, stepBySteps } = props
  const [expanded, setExpanded] = useState('')

  if (!options) {
    return <></>
  }

  return (
    <div>
      {options.map((option) => (
        <Accordion
          key={option.id}
          title={option.text}
          id={`${option.id}`}
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <S.AccordionContentContainer>
            <PensionCardSteps
              steps={stepBySteps?.find((step) => step.id === option.id)?.steps || []}
            />
            <PensionCardImportant
              info={stepBySteps?.find((step) => step.id === option.id)?.important}
            />
          </S.AccordionContentContainer>
        </Accordion>
      ))}
    </div>
  )
}
