export default {
  title: 'Aprenda a declarar suas ações no IR',
  subtitle:
    'Aqui, você encontra de maneira simples as informações complementares para fazer a sua declaração.',
  steps: [
    {
      step: 'Passo 01',
      title: 'Tenha o custo médio de cada ação',
      subtitle: 'Descubra como encontrar essas informações.',
      options: [
        { id: 1, text: 'Nota de Corretagem' },
        { id: 2, text: 'Canal Eletrônico do Investidor (CEI)' },
      ],
    },
    {
      step: 'Passo 02',
      title: 'Preencha a ficha de Bens e Direitos',
      subtitle: 'Confira as informações necessárias para cada ação a ser declarada.',
      options: [{ id: 3, text: 'Mostrar passo a passo' }],
    },
    {
      step: 'Passo 03',
      title: 'Declare seus proventos',
      subtitle: 'Recebeu proventos? Veja como declarar.',
      options: [
        { id: 4, text: 'Rendimentos isentos e não tributáveis' },
        { id: 5, text: 'Rendimentos sujeitos à tributação' },
      ],
    },
    {
      step: 'Passo 04',
      title: 'Operações em Day-Trade',
      subtitle: 'Declare seus lucros ou prejuízos em suas operações ao longo do ano.',
      options: [{ id: 6, text: 'Mostrar passo a passo' }],
    },
  ],
  stepByStep: [
    {
      id: 1,
      content: [
        {
          icon: 'number_one',
          text: 'Acessar [nota de corretagem](bancointer://investimentos/investir/rendaVariavel/notasCorretagem)',
        },
        { icon: 'number_two', text: 'Filtre pelos ativos adquiridos ao longo do ano base' },
        {
          icon: 'number_three',
          text: 'Faça o cálculo da média ponderada',
        },
      ],
    },
    {
      id: 2,
      description: 'Você também pode encontrar essas informações diretamente pelo CEI.',
      content: [
        { icon: 'number_one', text: 'Faça o login no CEI;', rightIcon: 'question_mark' },
        { icon: 'number_two', text: 'Clique em **extrato e informativos**;' },
        { icon: 'number_three', text: 'Acesse **negociação de ativos**;' },
        { icon: 'number_four', text: 'Filtre o primeiro e o último dia do ano retroativo;' },
        {
          icon: 'number_five',
          text: 'Em “Resumo dos negócios no período”, consulte o **preço médio** de cada ação.',
        },
      ],
    },
    {
      id: 3,
      textAfterClick: 'Ocultar passo a passo',
      content: [
        {
          icon: 'number_one',
          text: 'Digite o código da operação',
          description: 'Para ações, o código é o 31.',
        },
        {
          icon: 'number_two',
          text: 'Informe o CNPJ da empresa',
          description: 'Você encontra no detalhamento do seu [Informe de rendimentos]().',
        },
        {
          icon: 'number_three',
          text: 'Preencha o campo discriminação',
          description:
            'Informe a quantidade de ações, o código de negociação do ativo na bolsa e o valor médio de aquisição.<br/><br/> **Ex.: 50 ações de Banco Inter SA, código de negociação BIDI4, ao custo médio de R$15,00.**',
        },
        {
          icon: 'number_four',
          text: 'Informe a situação ao final do período',
          description: 'Multiplique a quantidade de ativos pelo custo médio de aquisição.',
        },
      ],
    },
    {
      id: 4,
      description: 'Declare seus lucros e dividentos recebidos.',
      content: [
        {
          icon: 'number_one',
          text: 'Digite o código da operação',
          description: '**Cód. 09** - Lucros e dividentos recebidos',
        },
        {
          icon: 'number_two',
          text: 'Identifique a empresa',
          description: 'Informe o CNPJ e Nome da Fonte pagadora.',
        },
        {
          icon: 'number_three',
          text: 'Informe o valor total recebido',
          description:
            'Preencha o valor total de rendimentos recebidos ao longo do período.<br/><br/> Você encontra essas informações no detalhamento do seu [informe de rendimentos]().',
        },
      ],
    },
    {
      id: 5,
      description: 'Declare seus Juros Sobre Capital Próprio (JCP).',
      content: [
        {
          icon: 'number_one',
          text: 'Digite o código da operação',
          description: '**Cód. 10** - Juros sobre capital próprio',
        },
        {
          icon: 'number_two',
          text: 'Identifique a empresa',
          description: 'Informe o CNPJ e Nome da Fonte pagadora.',
        },
        {
          icon: 'number_three',
          text: 'Informe o valor total recebido',
          description:
            'Preencha o valor total de rendimentos recebidos ao longo do período.<br/><br/> Você encontra essas informações no detalhamento do seu [informe de rendimentos]().',
        },
      ],
    },
    {
      id: 6,
      textAfterClick: 'Ocultar passo a passo',
      content: [
        {
          icon: 'number_one',
          text: 'Acesse a ficha de day trade',
          description: 'Em Renda Variável, selecione a opção Operações Comuns/Day Trade;',
        },
        {
          icon: 'number_two',
          text: 'Informe lucros ou prejuízos',
          description:
            'Preencha o valor de lucro ou prejuízo obtido a cada mês, conforme o ativo correspondente. Se houver prejuízo, Insira na frente do valor o sinal de menos (-);',
        },
        {
          icon: 'number_three',
          text: 'Caso não tenha operações em um mês',
          description: 'Basta colocar o número zero no campo indicado no mês correspondente.',
        },
      ],
    },
  ],
  footer: {
    title: 'Conheça nossas ferramentas',
    description: 'Ela podem te ajudar na declaração de Renda Variável.',
    content: [
      {
        title: 'IR Trade',
        description: 'Contabilidade especializada em Bolsa, IR e Regularização Fiscal',
        url: 'bancointer://investimentos/investir/shopping/ir-trade',
        urlImage:
          'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/investimentos/assessoria/invest-pro/imagens/logos/logo-ir-trade.png',
      },
      {
        title: 'Bússola do Investidor',
        description:
          'Calculadora de IR na Bolsa, carteira de investimentos e informação de valores',
        url: 'bancointer://investimentos/investir/shopping/bussola-do-investidor',
        urlImage:
          'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/investimentos/assessoria/invest-pro/imagens/logos/logo-bussola-do-investidor.png',
      },
    ],
  },
  important: {
    title: 'Compensar IR retido na fonte',
    description:
      'Você deverá inserir o valor retido a cada mês pela corretora no campo “Consolidação do Mês”, logo ao final da ficha do mês correspondente, nas linhas IR fonte Day-Trade a compensar.<br/><br/> Para fazer o cálculo do imposto, utilize as notas de corretagem. Está com dúvidas de como calcular? [Saiba como calcular]().',
  },
  bottomsheet: {
    title: 'CEI',
    description:
      'O Canal Eletrônico do Investidor – CEI é uma plataforma disponibilizada pela B3, em que o investidor pode consultar os extratos de posições e movimentações de todas as suas aplicações financeiras.',
  },
}
