import React, { useState } from 'react'

import { BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { requestAnalytics } from '@/services/bridge'
import { sendEmail } from '@/services/api/send-email'
import { useSnackBar } from '@interco/inter-ui/components/SnackBar'

import { SectionTitle } from '..'

import * as S from './styles'
import Loading from '../loading'

export interface IBottonSheetDemonstrativeProps {
  isVisible: boolean
  isRetroative: boolean
  fileDownload: HomePfd
  onClose: () => void
  onSendEmail: () => void
  choiceYear: string
}

export const BottonSheetDemonstrative = ({
  isVisible,
  fileDownload,
  onClose,
  onSendEmail,
  choiceYear,
  isRetroative,
}: IBottonSheetDemonstrativeProps) => {
  const [{ user, accountType, mobilePlatform }] = useGlobalState()
  const [loading, setLoading] = useState(false)
  const { showSnackBar } = useSnackBar()

  const asyncCallWithTimeout = ({
    asyncPromise,
    timeLimit = 5000,
    showSuccessFeedback = true,
  }: {
    asyncPromise: Promise<unknown>
    timeLimit?: number
    showSuccessFeedback?: boolean
  }) => {
    let timeoutHandle: NodeJS.Timeout
    const timeoutPromise = new Promise((_resolve, reject) => {
      timeoutHandle = setTimeout(
        () => reject(new Error(JSON.stringify({ timeout: true }))),
        timeLimit,
      )
    })
    return Promise.race([asyncPromise, timeoutPromise])
      .then((result) => {
        setLoading(false)
        onClose()
        if (showSuccessFeedback)
          showSnackBar({
            title: 'Download finalizado!',
            type: 'SUCCESS',
            content: 'Demonstrativo foi baixado com sucesso',
          })
        clearTimeout(timeoutHandle)
        return result
      })
      .catch((error) => {
        setLoading(false)
        onClose()
        const genericError = () =>
          showSnackBar({
            title: 'Erro ao baixar demonstrativo',
            type: 'ALERT',
            content: 'Tente novamente mais tarde',
          })
        try {
          if (JSON.parse(error.message).timeout) {
            showSnackBar({
              title: 'Download iniciado',
              type: 'NULL',
              content: 'Verifique se o demostrativo foi baixado ou tente novamente',
              customIcon: <></>,
            })
          } else {
            genericError()
          }
        } catch {
          genericError()
        }
      })
  }

  const handleDownload = async (file: HomePfd) => {
    setLoading(true)
    asyncCallWithTimeout({
      asyncPromise: BridgeService.downloadFile(file),
    })

    requestAnalytics('Download')
  }
  const handleSendEmail = async () => {
    try {
      await sendEmail({
        account: user?.account as string,
        accountType,
        ssn: user?.cpf || '',
        year: choiceYear,
      })

      onClose()
      onSendEmail()
    } catch (error) {
      onClose()
      showSnackBar({
        title: 'Erro ao enviar e-mail',
        type: 'ALERT',
        content: 'Tente novamente mais tarde',
      })
    }
  }

  const handleShare = async (file: HomePfd) => {
    const bridgeFunc =
      mobilePlatform === 'Android'
        ? BridgeService.shareFileAndroid(file)
        : BridgeService.downloadFile(file)

    asyncCallWithTimeout({
      asyncPromise: bridgeFunc,
      showSuccessFeedback: false,
    })
    setLoading(true)

    requestAnalytics('Download')
  }

  if (!isVisible) return null

  return (
    <>
      <S.BottomSheet onClose={onClose}>
        {loading ? (
          <S.LoadingContainer data-testid="loadding-container">
            <Loading />
          </S.LoadingContainer>
        ) : (
          <S.Container data-testid="bottomSheet-container">
            <SectionTitle>Gerar demonstrativo</SectionTitle>
            <S.Space />
            <S.Text variant="body-3">Escolha como você deseja ter o arquivo:</S.Text>

            <>
              <S.Space height={24} />
              {mobilePlatform === 'Android' && (
                <S.Item data-testid="download-button" onClick={() => handleDownload(fileDownload)}>
                  <div>
                    <S.IconDownload />
                    <S.Text variant="caption-1" colorWeight={500} bold>
                      Download do arquivo
                    </S.Text>
                  </div>
                  <S.IconChevronRight />
                </S.Item>
              )}

              {!isRetroative && (
                <S.Item onClick={handleSendEmail} data-testid="email-button">
                  <div>
                    <S.IconEmail />
                    <S.Text variant="caption-1" bold colorWeight={500}>
                      Receber por e-mail
                    </S.Text>
                  </div>
                  <S.IconChevronRight />
                </S.Item>
              )}

              <S.Item onClick={() => handleShare(fileDownload)} data-testid="share-button">
                <div>
                  <S.IconShare />
                  <S.Text variant="caption-1" colorWeight={500} bold>
                    Compartilhar
                  </S.Text>
                </div>
                <S.IconChevronRight />
              </S.Item>
            </>
          </S.Container>
        )}
      </S.BottomSheet>
    </>
  )
}
