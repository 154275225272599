import styled from 'styled-components'

import { Text as UIText } from '@interco/inter-ui/components/Text'

const CIRCLE_SIZE = 24

export const Container = styled.div``

export const Text = styled(UIText)`
  font-weight: bold;
  color: var(--typography500);
`

export const StepContainer = styled.div`
  display: grid;
  grid-template-columns: ${CIRCLE_SIZE}px 1fr;
  justify-content: space-between;
  margin-bottom: 28px;
`

export const StepContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 16px;
  a {
    color: var(--primary500);
    text-decoration: none;
    font-weight: bold;
  }
`

export const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray500);
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  color: var(--neutral-theme);
  border-radius: var(--radiiXlg);
  font-size: 12px;
`
