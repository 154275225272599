import React, { ReactNode, isValidElement } from 'react'

import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'

type StepProps = {
  title: string | ReactNode
  description: string | ReactNode
}

type GuideStepsProps = {
  steps: StepProps[]
  mgSmall?: boolean
}

export const GuideSteps = ({ steps, mgSmall = false }: GuideStepsProps) => (
  <S.Container>
    {steps.map((step, index) => (
      <S.StepContainer key={`stepcontainer_${step.title}_${step?.description}`} mg={mgSmall}>
        <S.StepNumber>{index + 1}</S.StepNumber>

        <S.StepContent>
          <div>
            <S.Text variant="body-3" color="typography">
              {step.title}
            </S.Text>
          </div>

          <div>
            {isValidElement(step.description) ? (
              step.description
            ) : (
              <Text variant="body-3" color="typography">
                {step.description}
              </Text>
            )}
          </div>
        </S.StepContent>
      </S.StepContainer>
    ))}
  </S.Container>
)
