import React, { useRef } from 'react'

import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { Text } from '@interco/inter-ui/components/Text'
import useEventLintener from '@/hooks/useEventListener'
import { formatMarkdownToLink } from '@/utils/string/format'

import * as S from './styles'

type GuideStepsProps = {
  steps: StepByStepStep[]
}

export const PensionCardSteps = ({ steps }: GuideStepsProps) => {
  const refCt = useRef<HTMLDivElement>(null)

  useEventLintener(refCt)

  return (
    <S.Container ref={refCt}>
      {steps.map((step, index) => (
        <S.StepContainer key={`stepcontainer_${step.title}`}>
          <S.StepNumber>{index + 1}</S.StepNumber>

          <S.StepContent>
            <div>
              <S.Text variant="body-3" color="typography">
                <ReactMarkdown>{step.title}</ReactMarkdown>
              </S.Text>
            </div>
            {step.description && (
              <Text variant="body-3" color="typography">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {formatMarkdownToLink(step.description)}
                </ReactMarkdown>
              </Text>
            )}
          </S.StepContent>
        </S.StepContainer>
      ))}
    </S.Container>
  )
}
