export default {
  choiceYear: '2021',
  title: 'Gerar seu demonstrativo',
  titleYear: 'Escolha o ano base para o cálculo',
  yearWithoutIr: false,
  demonstrative: {
    year: '2021',
    downloadLink: {
      name: 'Conta Corrente',
      fileName: '09243309960_2021_09243309960_2021_09243309960_2021_09243309960_2021CC.pdf',
      url: 'https://www.caceres.mt.gov.br/fotos_institucional_downloads/2.pdf',
    },
    alreadyAvailable: true,
    since: 'Disponível desde 19/02/2021',
    icon: 'Clipboard',
    title: 'IRPF',
    subtitle: 'Este demonstrativo inclui',
    products: ['Conta Corrente', 'Invetimentos', 'Empréstimo Imobiliário'],

    buttons: [
      {
        name: 'Gerar demonstrativo',
        suffixIcon: 'chevron-left',
      },
      {
        name: 'Saiba como declarar',
        suffixIcon: 'chevron-left',
      },
    ] /*
    retroative: [
      {
        name: '09243309960_2021_CC.pdf',
        fileName: '09243309960_2021_CC.pdf',
        url: 'https://invf-prd-informe-rendimentos.s3.sa-east-1.amazonaws.com/arq/09243309960_2021_CC.pdf?X-Amz-Security-Token=FwoGZXIvYXdzEN7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDBqArDC2qDjZ%2BIc6cyKtAVFGkzQvJGt9yobaUxqg2rh6dNC46u22UpqGgjU89RMmbWZ%2FFImBp4QSbr6cAIrzj2i1OpYRd91RUyZ4eEZKl6mTgJW905Hn%2FuRSnbmfSpgusAp1Ht34l5OPZ3lX1C2NoSU9kHaQt2AFTq8n9BbBGs2su5bIHxtvK%2BllHQajsBRnVJWajlH1jb89beac94QBCmEbvjduaVpArYMdbCz2GQz%2F7160BdPa%2FXKFrp9AKLfrjpAGMi3%2FLfZJJUdyxvUnqP2pyuXAJy0hFsX%2FOAnVZP9LGFBNvzMUk1ujtyA4vaPGhy4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220209T124202Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=ASIA3CFYEMDRCGL2M36H%2F20220209%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6da2e6fb87a1ec1c79eba948acae0f4b328262b66d41545f9fb6b9514ae0ed18',
      },
      {
        name: '09243309960_2021_CC.pdf',
        fileName: '09243309960_2021_CC.pdf',
        url: 'https://invf-prd-informe-rendimentos.s3.sa-east-1.amazonaws.com/arq/09243309960_2021_CC.pdf?X-Amz-Security-Token=FwoGZXIvYXdzEN7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDBqArDC2qDjZ%2BIc6cyKtAVFGkzQvJGt9yobaUxqg2rh6dNC46u22UpqGgjU89RMmbWZ%2FFImBp4QSbr6cAIrzj2i1OpYRd91RUyZ4eEZKl6mTgJW905Hn%2FuRSnbmfSpgusAp1Ht34l5OPZ3lX1C2NoSU9kHaQt2AFTq8n9BbBGs2su5bIHxtvK%2BllHQajsBRnVJWajlH1jb89beac94QBCmEbvjduaVpArYMdbCz2GQz%2F7160BdPa%2FXKFrp9AKLfrjpAGMi3%2FLfZJJUdyxvUnqP2pyuXAJy0hFsX%2FOAnVZP9LGFBNvzMUk1ujtyA4vaPGhy4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220209T124202Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=ASIA3CFYEMDRCGL2M36H%2F20220209%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6da2e6fb87a1ec1c79eba948acae0f4b328262b66d41545f9fb6b9514ae0ed18',
      },
      {
        name: '09243309960_2021_CC.pdf',
        fileName: '09243309960_2021_CC.pdf',
        url: 'https://invf-prd-informe-rendimentos.s3.sa-east-1.amazonaws.com/arq/09243309960_2021_CC.pdf?X-Amz-Security-Token=FwoGZXIvYXdzEN7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDBqArDC2qDjZ%2BIc6cyKtAVFGkzQvJGt9yobaUxqg2rh6dNC46u22UpqGgjU89RMmbWZ%2FFImBp4QSbr6cAIrzj2i1OpYRd91RUyZ4eEZKl6mTgJW905Hn%2FuRSnbmfSpgusAp1Ht34l5OPZ3lX1C2NoSU9kHaQt2AFTq8n9BbBGs2su5bIHxtvK%2BllHQajsBRnVJWajlH1jb89beac94QBCmEbvjduaVpArYMdbCz2GQz%2F7160BdPa%2FXKFrp9AKLfrjpAGMi3%2FLfZJJUdyxvUnqP2pyuXAJy0hFsX%2FOAnVZP9LGFBNvzMUk1ujtyA4vaPGhy4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220209T124202Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=ASIA3CFYEMDRCGL2M36H%2F20220209%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6da2e6fb87a1ec1c79eba948acae0f4b328262b66d41545f9fb6b9514ae0ed18',
      },
      {
        name: '09243309960_2021_CC.pdf',
        fileName: '09243309960_2021_CC.pdf',
        url: 'https://invf-prd-informe-rendimentos.s3.sa-east-1.amazonaws.com/arq/09243309960_2021_CC.pdf?X-Amz-Security-Token=FwoGZXIvYXdzEN7%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDBqArDC2qDjZ%2BIc6cyKtAVFGkzQvJGt9yobaUxqg2rh6dNC46u22UpqGgjU89RMmbWZ%2FFImBp4QSbr6cAIrzj2i1OpYRd91RUyZ4eEZKl6mTgJW905Hn%2FuRSnbmfSpgusAp1Ht34l5OPZ3lX1C2NoSU9kHaQt2AFTq8n9BbBGs2su5bIHxtvK%2BllHQajsBRnVJWajlH1jb89beac94QBCmEbvjduaVpArYMdbCz2GQz%2F7160BdPa%2FXKFrp9AKLfrjpAGMi3%2FLfZJJUdyxvUnqP2pyuXAJy0hFsX%2FOAnVZP9LGFBNvzMUk1ujtyA4vaPGhy4%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220209T124202Z&X-Amz-SignedHeaders=host&X-Amz-Expires=259200&X-Amz-Credential=ASIA3CFYEMDRCGL2M36H%2F20220209%2Fsa-east-1%2Fs3%2Faws4_request&X-Amz-Signature=6da2e6fb87a1ec1c79eba948acae0f4b328262b66d41545f9fb6b9514ae0ed18',
      },
    ], */,
  },
  years: [{ year: '2019' }, { year: '2020' }, { year: '2021' }],
  searchBlog: ['imposto%20de%20renda'],
  /* metadata: {
    basePeriods: [
      {
        id: 'ANO',
        description: 'Anual',
      },
      {
        id: 'PRI',
        description: 'Primeiro Trimestre',
      },
      {
        id: 'SEG',
        description: 'Segundo Trimestre',
      },
      {
        id: 'TER',
        description: 'Terceiro Trimestre',
      },
      {
        id: 'QUA',
        description: 'Quarto Trimestre',
      },
    ],
  }, */
  data: { choiceBasePeriod: 'PRI', accountType: 'PF' },
  explicativeContents: [
    {
      image:
        'https://static.bancointer.com.br/blog/grid-images/edf95e34-c933-4b48-bc16-811b0598f360.jpg',
      OnClick: 'https://blog.bancointer.com.br/documentos-imposto-de-renda',
    },
    {
      image:
        'https://static.bancointer.com.br/blog/grid-images/edf95e34-c933-4b48-bc16-811b0598f360.jpg',
      OnClick: 'https://blog.bancointer.com.br/documentos-imposto-de-renda',
    },
  ],
}
