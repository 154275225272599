import InterWebviewBridge, {
  interWbSession,
  interWbHttp,
  interWbNavigate,
  IWbUserInfo,
  IWbHttpOptions,
  IWbHttpData,
  WbEnvironments,
} from '@interco/inter-webview-bridge'
import USER_INFO_MOCK from '@/common/mocks/responses/user-info'

import { DeepLinksPF, DeepLinksPJ, DeepLinks } from './deeplinks'
import * as Types from './types'

export { interWbNavigate } from '@interco/inter-webview-bridge'
export { DeepLinks, DeepLinksPJ, DeepLinksPF }

export const iWb = InterWebviewBridge.getInstance()
export const inApp = iWb.getEnvironment() !== WbEnvironments.BROWSER

export const getUserInfo = (): Promise<IWbUserInfo> =>
  inApp ? interWbSession.getUserInfo() : Promise.resolve(USER_INFO_MOCK)

export const requestAnalytics = (name: string, params?: Record<string, string>) => {
  if (!inApp) return
  interWbSession.requestAnalytics(name, params)
}

export async function request<T = Record<string, unknown>>({
  method,
  endpoint,
  ...args
}: Types.Request): Promise<T> {
  let response: IWbHttpData
  const headers = args.headers || {}
  const options: IWbHttpOptions = { timeout: 30_000, ...args.options }

  switch (method) {
    default:
    case 'GET':
    case 'DELETE':
      response = await interWbHttp[method.toLowerCase() as 'get' | 'delete'](
        endpoint,
        headers,
        options,
      )
      break

    case 'POST':
    case 'PUT':
      response = await interWbHttp[method.toLowerCase() as 'post' | 'put'](
        endpoint,
        (args as Types.PostOrPut).body,
        headers,
        options,
      )
      break
  }

  const data = response
  return JSON.parse(data.response || '[]')
}

export const getSuperAppType = async (): Promise<AppType | undefined> => {
  if (inApp) {
    const resp = await interWbSession.getAppInfo()
    if (resp.application) {
      return resp.application
    }
    const auxi = await iWb.execute<Types.SuperAppTypeOld, undefined>({
      action: Types.Actions.GET_SUPER_APP_TYPE,
    })
    return auxi.superAppType === 'INTER_PF' ? 'PF' : 'PJ'
  }

  return Promise.resolve(undefined)
}

export const navigateDeepLink = async (url: string): Promise<unknown> => {
  if (!inApp) {
    return Promise.resolve(undefined)
  }

  return interWbNavigate.openDeepLink(url)
}

export const shareWebState = (): Promise<void> => {
  if (inApp) {
    return iWb.execute<void, undefined>({
      action: Types.Actions.SHARE_WEB_VIEW_SCREENSHOT,
    })
  }

  return Promise.resolve(undefined)
}

export const getSessionType = async (): Promise<Types.SessionType> => {
  if (inApp) {
    return iWb.execute<Types.SessionType, unknown>({
      action: Types.Actions.GET_SESSION_TYPE,
    })
  }

  return Promise.resolve({ isBasicAccount: false })
}

export const downloadFile = (fileUrl: Record<string, string>): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.DOWNLOAD_FILE,
      metadata: {
        fileUrl,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const shareFileAndroid = (fileUrl: Record<string, string>): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.SHARE_FILE_ANDROID,
      metadata: {
        fileUrl,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const verifyOnboarding = (): Promise<Types.VerifyOnboarding> => {
  if (inApp) {
    return iWb.execute<Types.VerifyOnboarding, unknown>({
      action: Types.Actions.GET_ONBOARDING,
    })
  }

  return Promise.resolve({ onboardingWasPresented: false })
}

export const setOnboarding = (onboardingWasPresented: boolean): Promise<unknown> => {
  if (inApp) {
    return iWb.execute<unknown, unknown>({
      action: Types.Actions.SET_ONBOARDING,
      metadata: {
        onboardingWasPresented,
      },
    })
  }

  return Promise.resolve(undefined)
}

export const openNativeBrowser = (url: string): Promise<string | void> => {
  if (inApp) {
    return iWb.execute<string, unknown>({
      action: Types.Actions.OPEN_NATIVE_BROWSER,
      metadata: { url },
    })
  }

  return Promise.resolve(undefined)
}
