import styled, { css } from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'

interface IRowProps {
  oneItem?: boolean
}
export const RowItem = styled.div<IRowProps>`
  ${({ oneItem }) => css`
    margin-bottom: 16px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    ${oneItem &&
    css`
      justify-content: flex-start;
      display: none;
    `}
  `}
`
export const TextLine = styled(TextUI)`
  display: flex;
  align-items: center;
`

export const ContainerValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const TextRight = styled(TextUI)`
  text-align: right;
  padding-left: 6px;
`
