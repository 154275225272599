import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'
import { Icon } from '@/components/IconComponent'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Text = styled(TextUI)``

export const List = styled.ul`
  list-style: none;
  li {
    border-bottom: 1px solid var(--gray100);
  }

  li:last-child {
    border-bottom: 0;
  }
`

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
`
interface IContentImage {
  url: string
}
export const ContentImage = styled.div`
  padding: 15px;
  border: 1px solid var(--gray100);
  border-radius: 10px;
  margin-right: 16px;
`

export const Image = styled.div<IContentImage>`
  border: 1px solid #000;
  background-image: ${(props) => (props.url ? `url(${props.url})` : '')};
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: auto;
  border: 0;
`

export const ListItemInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
`

export const IconChevronRight = styled(Icon).attrs({
  width: 24,
  height: 24,
  name: 'chevron-right',
})``
