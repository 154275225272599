import React from 'react'

import ReactMarkdown from 'react-markdown'

import { Text } from '@interco/inter-ui/components/Text'
import { usePension } from '@/hooks'

import * as S from './styles'

type PensionCardNavProps = {
  pension?: PensionPension
}

export const PensionCardNav = ({ pension }: PensionCardNavProps) => {
  const { handleSelectPage } = usePension()

  if (!pension) {
    return <> </>
  }

  return (
    <S.Container>
      <S.Description>
        <S.Text variant="body-3" color="typography" colorWeight={400}>
          <ReactMarkdown>{pension?.description}</ReactMarkdown>
        </S.Text>
      </S.Description>
      {pension.buttons.map((button) => (
        <S.StepContainer
          key={`stepcontainer_${button.text}_${button?.description}`}
          onClick={() => handleSelectPage(pension.options.find((item) => item.id === button.id))}
        >
          <S.StepContent>
            <S.Text variant="body-3" color="typography" bold colorWeight={500}>
              <ReactMarkdown>{button.text}</ReactMarkdown>
            </S.Text>
            {button.description && (
              <Text variant="caption-1" color="typography" colorWeight={400}>
                <ReactMarkdown>{button.description}</ReactMarkdown>
              </Text>
            )}
          </S.StepContent>
          <S.ContentArrow>
            <S.IconArrowRight />
          </S.ContentArrow>
        </S.StepContainer>
      ))}
    </S.Container>
  )
}
