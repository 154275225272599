import React from 'react'

import * as S from './styles'

type Props = {
  title?: string
  description?: string
}

export const ProductNotAvailable = ({ title, description }: Props) => (
  <S.Container>
    <S.Text variant="headline-h3" semiBold colorWeight={500}>
      {title}
    </S.Text>

    <S.Space height={16} />

    <S.Text variant="body-3" colorWeight={400}>
      {description}
    </S.Text>
  </S.Container>
)

ProductNotAvailable.defaultProps = {
  title: 'Informe não disponível',
  description:
    'Não existem demonstrativos de informe de rendimento a serem exibidos para o ano base selecionado. Informe outro período e faça sua consulta.',
}
