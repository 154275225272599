import React from 'react'

import ReactMarkdown from 'react-markdown'

import * as S from './styles'
import { StepByStepPension } from '../step-by-step-pension'
import { PensionStepNav } from '../pension-step-nav'

interface StepPensionProps {
  cardStep: PensionSteps
  stepBySteps?: Array<PensionStepByStep>
  pension?: Array<PensionPension>
}

export const StepPension = ({ cardStep, stepBySteps, pension }: StepPensionProps) => {
  const { step, title, subtitle, options, type } = cardStep

  return (
    <S.Container>
      <S.Text variant="body-3" bold>
        <ReactMarkdown>{step || ''}</ReactMarkdown>
      </S.Text>

      <S.Space />

      <S.Text variant="headline-h3" semiBold>
        <ReactMarkdown>{title || ''}</ReactMarkdown>
      </S.Text>

      <S.Space />

      <S.Text variant="body-3">
        <ReactMarkdown>{subtitle || ''}</ReactMarkdown>
      </S.Text>

      <S.Space />

      <S.ContainerAccordion>
        {options && type === 'stepByStep' && (
          <StepByStepPension options={options} stepBySteps={stepBySteps} />
        )}
        {options && type === 'pension' && <PensionStepNav options={options} pension={pension} />}
      </S.ContainerAccordion>
    </S.Container>
  )
}
