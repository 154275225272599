import React, { ChangeEvent, useEffect, useState, useRef, useMemo } from 'react'

import { useInfiniteQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { useDebounce } from '@interco/lib-util'
import { BlogList, Header, SectionTitle } from '@/components'
import { RoutesNames } from '@/routes/routes'
import { getBlogs } from '@/services/api/blog'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import { Text } from '@interco/inter-ui/components/Text'
import Loading from '@/components/loading'
import useGlobalState from '@/contexts/global-state'

import * as S from './styles'

const ITEMS_PER_PAGE = 15

export const Blogs = () => {
  const history = useHistory()
  const [{ home }] = useGlobalState()
  const loadMoreButtonRef = useRef<HTMLButtonElement | null>(null)
  const [search, setSearch] = useState<string>('')
  const [blogList, setBlogList] = useState<BlogHit[]>([])
  const debouncedValue = useDebounce<string>(search, 300)
  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    'blog-load-more',
    async ({ pageParam }) =>
      getBlogs({
        size: ITEMS_PER_PAGE,
        page: pageParam || 0,
        search: home?.searchBlog ? home?.searchBlog.join('%20') : '',
      }),
    {
      getNextPageParam: ({ start, found }) => {
        if (found <= ITEMS_PER_PAGE) {
          return false
        }
        if (start <= found) {
          return start + ITEMS_PER_PAGE
        }

        return false
      },
      refetchOnWindowFocus: false,
    },
  )

  const handleSearchBlog = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setSearch(value)
  }

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage!,
  })

  useEffect(() => {
    if (search) {
      const blogListFiltered = data?.pages.map((page) =>
        page.hit.filter((item: BlogHit) =>
          item.fields.title.toLowerCase().includes(search.toLowerCase()),
        ),
      )

      return setBlogList(blogListFiltered?.flat() || [])
    }

    return setBlogList([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  const renderItems = useMemo(() => {
    if (blogList.length === 0 && search) {
      return (
        <S.NotFound>
          <Text variant="body-3" bold colorWeight={400}>
            Não encontramos nenhuma postagem correspondente ao termo de pesquisa informado.
          </Text>
        </S.NotFound>
      )
    }

    if (blogList.length > 0) {
      return <BlogList blogList={blogList} />
    }

    return (
      <>
        {data?.pages.map((page) => (
          <BlogList blogList={page.hit} />
        ))}{' '}
      </>
    )
  }, [blogList, data?.pages, search])

  return (
    <S.Container>
      <Header onRightButtonClick={() => null} onBackClick={() => history.push(RoutesNames.HOME)}>
        Conteúdo
      </Header>
      <S.ContainerSearch>
        <S.Input value={search} onChange={handleSearchBlog} />
      </S.ContainerSearch>

      <S.Space height={24} />

      <SectionTitle>Conteúdos sobre IR</SectionTitle>

      {renderItems}

      <S.LoadingMore hidden={blogList.length > 0 || !!search}>
        <button
          ref={loadMoreButtonRef}
          type="button"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage && <Loading />}
        </button>
      </S.LoadingMore>
    </S.Container>
  )
}
