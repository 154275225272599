import styled, { keyframes } from 'styled-components'

import { Icon } from '../IconComponent'

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const LoadingIcon = styled(Icon)`
  animation: ${rotate} 1.1s infinite linear;
`
