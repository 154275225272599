import React from 'react'

import * as S from './styles'

const Table = ({ headers, rows, rowTotal }: Table) => (
  <S.Table>
    <S.TrHeader>
      {headers.map((value) => (
        <th data-testid="columns">
          <S.TextLine variant="caption-1" colorWeight={400}>
            {value}
          </S.TextLine>
        </th>
      ))}
    </S.TrHeader>
    <S.TrSpace />
    {rows.map((row) => (
      <S.TrRows data-testid="rows">
        {row.map((value) => (
          <td data-testid="cells">
            <S.TextLine variant="caption-1" colorWeight={400}>
              {value}
            </S.TextLine>
          </td>
        ))}
      </S.TrRows>
    ))}
    {rowTotal && (
      <>
        <S.TrRowsDoted />
        <S.TrRows />
        <S.TrRows>
          {rowTotal.map((row, index) =>
            index === 0 ? (
              <td>
                <S.TextLine variant="caption-1" colorWeight={400}>
                  {row}
                </S.TextLine>
              </td>
            ) : (
              <td>
                <S.TextLine variant="caption-1" colorWeight={500} bold>
                  {row}
                </S.TextLine>
              </td>
            ),
          )}
        </S.TrRows>
      </>
    )}
  </S.Table>
)

export default Table
