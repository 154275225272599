import React, { useRef } from 'react'

import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

import { formatMarkdownToLink } from '@/utils/string/format'
import useEventLintener from '@/hooks/useEventListener'

import { SectionTitle } from '..'

import * as S from './styles'

interface IBottonSheetVariableIncomeProps {
  title: string
  description: string
  isVisible: boolean
  onClose: () => void
}

export const BottonSheetSimple = ({
  title,
  description,
  isVisible,
  onClose,
}: IBottonSheetVariableIncomeProps) => {
  const refCt = useRef<HTMLDivElement>(null)

  useEventLintener(refCt)
  if (!isVisible) return null

  return (
    <>
      <S.BottomSheet onClose={onClose}>
        <S.Container ref={refCt}>
          <SectionTitle>{title}</SectionTitle>
          <S.Space height={12} />
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {formatMarkdownToLink(description)}
          </ReactMarkdown>
        </S.Container>
        <S.Space height={12} />
      </S.BottomSheet>
    </>
  )
}
