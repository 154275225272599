/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'

import { RoutesNames } from '@/routes/routes'
import { Header, LoadingSkeleton } from '@/components'
import { ApiService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { PensionProvider } from '@/contexts/PensionContext'

import * as S from './styles'
import { PensionPage } from './components/pension-page'

export const Pension = () => {
  const history = useHistory()
  const [{ user }] = useGlobalState()
  const [isPage, setIsPage] = useState(false)

  const handleBackPage = () => {
    if (isPage) {
      setIsPage(false)
    } else {
      history.goBack()
    }
  }

  const { isLoading, data } = useQuery<Pension | undefined>(
    'pension',
    async () =>
      ApiService.Pension.getPension({
        account: user?.account as string,
        accountType: 'PF',
      }),
    { retry: false, cacheTime: 0, refetchOnWindowFocus: false },
  )

  if (isLoading) {
    return (
      <S.ContainerLoading>
        <LoadingSkeleton />
      </S.ContainerLoading>
    )
  }

  return (
    <PensionProvider>
      <S.Container>
        <Header
          onRightButtonClick={() => history.push(RoutesNames.HOME)}
          rightIcon="home"
          onBackClick={handleBackPage}
        >
          Previdência
        </Header>
        {!data ? (
          <S.Text variant="body-2">Nenhum informação encontrada</S.Text>
        ) : (
          <PensionPage
            dataPension={data}
            isDetailsPage={isPage}
            onNavPage={(value) => setIsPage(value)}
          />
        )}
      </S.Container>
    </PensionProvider>
  )
}
