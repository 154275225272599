import styled from 'styled-components'

export const AccordionContentContainer = styled.div`
  padding-top: 28px;

  a {
    color: var(--primary500);
    text-decoration: none;
    font-weight: bold;
  }
`
