import styled from 'styled-components'

import { Accordion as AccordionUI } from '@interco/inter-ui/components/Accordion'
import { Text as TextUI } from '@interco/inter-ui/components/Text'

import { Icon } from '../IconComponent'

export const Accordion = styled(AccordionUI)`
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  > div {
    background-color: var(--neutral-theme);
    padding-right: 0 !important;
    padding: 0px !important;
  }
`

export const ContainerTitle = styled.div`
  height: 54px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`

export const Title = styled(TextUI)`
  display: flex;
  width: 100%;
`

export const Subtitle = styled(TextUI)``

export const Text = styled(TextUI)``

export const IconArrowUp = styled(Icon).attrs({
  height: 32,
  width: 32,
  color: 'var(--primary500)',
  name: 'chevron-up',
})`
  padding: 5px;
`

export const IconArrowDown = styled(Icon).attrs({
  height: 32,
  width: 32,
  color: 'var(--primary500)',
  name: 'chevron-down',
})`
  padding: 5px;
`

export const ContainerChildren = styled.div`
  height: auto;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: -20px;
`
