import * as ENDPOINTS from '@/common/constants/endpoints'
import { shouldInsertAccount } from '@/utils/account'

import { api } from '../api'

export const getOnboarding = async ({ account, accountType }: DefaultHeader) =>
  api<Onboarding>(ENDPOINTS.ONBOARDING, {
    method: 'GET',
    headers: {
      'account-type': accountType,
      ...shouldInsertAccount({ accountType, account }),
    },
  })
