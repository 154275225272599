import styled, { css } from 'styled-components'

import { Input as InputUI } from '@interco/inter-ui/components/Input'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`

export const ContainerSearch = styled.div`
  width: calc(100% + 48px);
  padding: 24px 24px 0;
  border-bottom: 1px solid var(--gray200);
  margin-left: -24px;
`

export const Input = styled(InputUI).attrs({
  placeholder: 'Pesquisar',
})`
  width: 100%;

  input::placeholder {
    text-align: center;
  }
`

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Text = styled(TextUI)``

export const NotFound = styled.div`
  margin-top: 15px;
`
export const LoadingMore = styled.div<{ hidden: boolean }>`
  ${({ hidden }) => css`
    display: ${hidden ? 'none' : 'block'};
    text-align: center;
    margin-top: 15px;
  `}
`
