import React, { useState } from 'react'

import { Flex } from '@interco/inter-ui/components/Flex'
import { Icon } from '@/components/IconComponent'
import { ActionIdEnum } from '@/common/constants/services'
import { copyToClipboard } from '@/utils/string/copyPast'

import * as S from './styles'

export type ItemWithActionProps = ProductItem & {
  openBottomSheet?: (data: BottomSheet) => void
}

export const ItemWithAction = ({
  label,
  description,
  action,
  openBottomSheet,
}: ItemWithActionProps) => {
  const [copied, setCopied] = useState<string>('')

  const renderAction = () => {
    if (action === undefined) return undefined
    if (action.id === ActionIdEnum.BOTTOM_SHEET && openBottomSheet) {
      const { bottomSheet, icon } = action
      return (
        <S.ButtonIcon
          data-testid="button-bottom-sheet-item-with-action"
          onClick={() => openBottomSheet(bottomSheet)}
        >
          <Icon name={icon.id} color={`var(${icon.color})`} width={18} height={18} />
        </S.ButtonIcon>
      )
    }
    if (action.id === ActionIdEnum.COPY_PAST) {
      const { textToCopyToClipboard, icon } = action
      return (
        <S.ButtonIcon
          data-testid="button-copy-past"
          onClick={() =>
            copyToClipboard(textToCopyToClipboard || '', `${textToCopyToClipboard}`, setCopied)
          }
        >
          <Icon name={icon.id} color={`var(${icon.color})`} width={18} height={18} />
          <S.Tooltip active={copied === `${textToCopyToClipboard}`}>
            <span>copiado</span>
          </S.Tooltip>
        </S.ButtonIcon>
      )
    }
    return <></>
  }
  return (
    <Flex
      justifyContent="space-between"
      direction="row"
      alignContent="flex-end"
      alignItems="baseline"
      gap="16px"
      data-testid="itemWithAction"
    >
      <S.SectionMaxWidth>
        <S.LabelText variant="caption-1" colorWeight={500}>
          {label}
        </S.LabelText>
      </S.SectionMaxWidth>
      <S.SectionMaxWidth>
        <Flex
          justifyContent="flex-end"
          direction="row"
          alignContent="flex-end"
          alignItems="center"
          gap="4px"
        >
          <S.LabelTextAlignEnd variant="caption-1" bold colorWeight={500}>
            {description}
          </S.LabelTextAlignEnd>
          {action && renderAction()}
        </Flex>
      </S.SectionMaxWidth>
    </Flex>
  )
}
