import styled from 'styled-components'

import { Icon as IconUI } from '@/components/IconComponent'
import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Text = styled(TextUI)``

const FOOTER_HEIGHT = 80

export const Container = styled(GlobalStyles.DefaultContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;

  > svg {
    margin: 0 auto;
    max-width: 100%;
  }

  padding-bottom: ${FOOTER_HEIGHT}px;
  overflow-x: auto;

  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Icon = styled(IconUI)``

export const ContentDescription = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 24px;
  > span {
    margin-left: 35px;
  }
`
export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  svg {
    margin-right: 12px;
  }
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  margin-left: -24px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid var(--gray100);
  background-color: var(--neutral-theme);

  button {
    width: 100%;
  }
`
