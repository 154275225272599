import styled from 'styled-components'

import { Button as ButtonUI } from '@interco/inter-ui/components/Button'
import { Text as TextUI } from '@interco/inter-ui/components/Text'

export const Container = styled.div`
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--gray200);
  border-radius: 8px;
`

export const Text = styled(TextUI)``

export const Button = styled(ButtonUI)`
  width: 100%;
  margin-top: 16px;
`
