import { RoutesNames } from '@/routes/routes'

export const formatMarkdownToLink = (text: string) => {
  const regex = /\[(.*)\]\((.*)\)/
  const isDeepLink = /bancointer:\/\//

  const groups = regex.exec(text)

  if (groups && isDeepLink.test(groups[2])) {
    return text.replace(regex, `<button data-url="$2" className="open-deep-link">$1</button>`)
  }

  if (groups && Object.keys(RoutesNames).includes(groups?.[2])) {
    return text.replace(
      regex,
      `<button data-url="${RoutesNames.VARIABLE_INCOME}" className="link-react-router-dom">$1</button>`,
    )
  }

  if (regex.test(text)) {
    return text.replace(regex, `<button data-url="$2" className="open-native-link">$1</button>`)
  }

  return text
}
