import React from 'react'

import { SectionTitle } from '..'

import * as S from './styles'

interface IBottonSheetDemonstrativeSendedEmailProps {
  userEmail: string
  demonstrativeYear: string
  isVisible: boolean
  onClose: () => void
}

export const BottonSheetDemonstrativeSendedEmail = ({
  userEmail,
  demonstrativeYear,
  isVisible,
  onClose,
}: IBottonSheetDemonstrativeSendedEmailProps) => {
  if (!isVisible) return null

  return (
    <S.BottomSheet onClose={onClose}>
      <S.Container>
        <S.Space height={12} />
        <SectionTitle>Enviamos o seu informe de rendimentos por e-mail</SectionTitle>
        <S.Space />
        <S.Text variant="body-3">
          O seu informe de rendimento de {demonstrativeYear} foi enviado para o e-mail:
          <S.Text variant="body-3" bold>
            {userEmail}
          </S.Text>
        </S.Text>

        <S.Space height={24} />

        <S.Button variant="primary" onClick={onClose}>
          Fechar
        </S.Button>
      </S.Container>
    </S.BottomSheet>
  )
}
