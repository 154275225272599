import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled.div``

export const Space = styled(GlobalStyles.DefaultSpace)``

export const Text = styled(TextUI)``

export const PartnerSectionContainer = styled.div`
  li {
    border-bottom: 1px solid var(--gray200);
  }

  li:last-child {
    border: none !important;
  }
`
