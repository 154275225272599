import React from 'react'

import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { getBlogs } from '@/services/api/blog'
import { BlogList, SectionTitle } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { BridgeService } from '@/services'
import { requestAnalytics } from '@/services/bridge'

import * as S from './styles'

export const HomeBlog = ({ search }: { search: string }) => {
  const history = useHistory()
  const [{ home }] = useGlobalState()
  const { isFetching, data } = useQuery(
    'getBlogs',
    async () =>
      getBlogs({
        size: 3,
        page: 0,
        search,
      }),
    {
      refetchOnWindowFocus: false,
    },
  )

  return (
    <S.Container>
      <S.ContentHeader>
        <SectionTitle>Fique por dentro</SectionTitle>
        {home?.data?.accountType === 'PF' ? (
          <S.Text
            variant="body-3"
            bold
            color="primary"
            onClick={() => history.push(RoutesNames.BLOG)}
          >
            Ver todas
          </S.Text>
        ) : (
          <S.Text
            variant="body-3"
            bold
            color="primary"
            onClick={() => {
              BridgeService.openNativeBrowser(`https://blog.bancointer.com.br/para-o-empreendedor`)
              requestAnalytics('AcessarMaisNoticias')
            }}
          >
            Acessar mais notícias
          </S.Text>
        )}
      </S.ContentHeader>

      {isFetching && (
        <S.ContainerLoading>
          <S.IconLoading />
        </S.ContainerLoading>
      )}
      {!isFetching && data && data?.hit && <BlogList blogList={data.hit.slice(0, 3)} />}
    </S.Container>
  )
}
