import styled from 'styled-components'

import { Text as TextUI } from '@interco/inter-ui/components/Text'
import * as GlobalStyles from '@/styles/styles'

export const Container = styled(GlobalStyles.DefaultContainer)`
  ${() => GlobalStyles.DefaultHeaderStyles()}
`
export const Space = styled(GlobalStyles.DefaultSpace)``

export const Sepator = styled(GlobalStyles.DefaultSeparator)``

export const Text = styled(TextUI)``

export const ContainerProduct = styled.div`
  > div {
    border-bottom: 1px solid var(--gray200);
  }
  > div:last-child {
    border-bottom: 0;
  }
`
