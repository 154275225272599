import React, { PropsWithChildren } from 'react'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { SnackBarProvider } from '@interco/inter-ui/components/SnackBar'

import { GlobalStateProvider } from './global-state'
import { queryClient } from './query-client'
import StateInspector from './state-inspector'

const AppProviders = ({ children }: PropsWithChildren<unknown>) => (
  <StateInspector>
    <GlobalStateProvider>
      <QueryClientProvider client={queryClient}>
        <SnackBarProvider>
          {children}
          <ReactQueryDevtools initialIsOpen />
        </SnackBarProvider>
      </QueryClientProvider>
    </GlobalStateProvider>
  </StateInspector>
)

export default AppProviders
