import styled from 'styled-components'

import { Flex } from '@interco/inter-ui/components/Flex'

export const Container = styled.div`
  width: 100%;
`

export const ColumnFlex = styled(Flex).attrs({
  justifyContent: 'flex-start',
  direction: 'column',
  alignContent: 'flex-start',
  alignItems: 'baseline',
})`
  width: 100%;
`
