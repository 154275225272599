import styled from 'styled-components'

import { Text as UIText } from '@interco/inter-ui/components/Text'

export const SectionTitle = styled(UIText).attrs({
  variant: 'headline-h3',
  color: 'typography',
  colorWeight: 500,
})`
  font-weight: bold;
`
