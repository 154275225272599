import React, { memo } from 'react'

import { requestAnalytics } from '@/services/bridge'
import { BridgeService } from '@/services'

import * as S from './styles'

export interface IBlogItemSimpleProps {
  title: string
  introduction: string
  createdAt: string
  image: string
  slug: string
}

const BlogItemComponent = ({
  title,
  introduction,
  createdAt,
  image,
  slug,
}: IBlogItemSimpleProps) => (
  <S.Link
    data-testid="blog-lit-item"
    onClick={() => {
      BridgeService.openNativeBrowser(
        `https://blog.bancointer.com.br/${slug}?utm_source=app&utm_medium=informe_de_rendimentos_2022`,
      )
      requestAnalytics('CliqueConteudos', {
        content_name: slug,
      })
    }}
  >
    <S.Container>
      <S.Image url={image} />

      <S.ContentDescription>
        <S.Text variant="body-3" bold colorWeight={500}>
          {title}
        </S.Text>
        <S.LimitText variant="caption-2">{introduction}</S.LimitText>
        <S.Text variant="caption-2">{createdAt}</S.Text>
      </S.ContentDescription>
    </S.Container>
  </S.Link>
)

export const BlogItem = memo(BlogItemComponent)
