import React from 'react'

import rehypeRaw from 'rehype-raw'

import { Alert } from '@interco/inter-ui/components/Alert'

import * as S from './styles'

interface BoxInfoProps {
  title: string
  description: string
  action?: {
    title: string
    handleAction: (bool: boolean) => void
  }
}

export const BoxInfo = ({ title, description, action }: BoxInfoProps) => (
  <Alert title={title} type="grayscale" data-testid="box-info-alert">
    <S.DescriptionMarkwdown rehypePlugins={[rehypeRaw]}>{description}</S.DescriptionMarkwdown>
    {action && (
      <S.Button
        onClick={() => action.handleAction(true)}
        type="button"
        data-testid="box-info-button-action"
      >
        {action.title}
      </S.Button>
    )}
  </Alert>
)
